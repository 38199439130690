import { Sales as SalesRequests } from '@cbo/shared-library/request';
import { Sales as SalesResponses } from '@cbo/shared-library/response';
import { IPeriodFilter } from '@cbo/shared-library/response/calendar.response';
import { IDateRange, Req } from '@cbo/shared-library';
import { UserPreferences } from '@cbo/shared-library/response/admin.response';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import {
  HouseAccount,
  SaleTransaction,
  TransactionItem,
  TransactionTotals,
  GlAccount,
  GlAccountsRequest,
} from './requests/requests';
import {
  CreateTransactionPDFBodySchema,
  HouseAccountFormSchema,
  HouseAccountRequestSchema,
  TransactionLabels,
  TransactionItems,
  TransactionItemModifier,
  TransactionSubTax,
  BodyOverflowError,
} from './types';
import { formatDate, formatTime } from '../utils';
import { GlAccountsFilterIsActive } from './GeneralLedgerAccounts/GeneralLedgerAccountsManageReport';
import { GlobalFilterBarDateInput } from '../components/GlobalFilterBar/types';
import { getEndDate } from '../utils/reportingUtils/filterUtils';
import { MuiLineChartData } from '../components/Charts/LineChart/LineChart';

const houseAccountMapper = (data: HouseAccountFormSchema, isActive: boolean): HouseAccountRequestSchema => {
  const requestObj: HouseAccountRequestSchema = {
    contactEmail: data.contactEmail,
    isActive,
    maxBalance: data.maxBalance || 0,
    houseAccountName: data.houseAccountName,
    contactFirstName: data.contactFirstName,
    contactLastName: data.contactLastName,
    contactPhoneNumber: data.phoneNumber,
    address: data.address || '',
    address2: data.address2 || '',
    city: data.city || '',
    state: data.state || '',
    country: data.country || '',
    postalCode: data.postalCode || '',
    consumers: data.consumers ?? [],
    sites: data.sites ?? [],
  };
  return requestObj;
};

const houseAccountFormMapper = (data: HouseAccount): HouseAccountFormSchema => {
  const houseAccountFormDetails: HouseAccountFormSchema = {
    isActive: data.isActive,
    houseAccountNumber: data.houseAccountNumber,
    contactEmail: data.contactEmail,
    maxBalance: data.maxBalance || 0,
    houseAccountName: data.houseAccountName,
    contactFirstName: data.contactFirstName,
    contactLastName: data.contactLastName,
    phoneNumber: data.contactPhoneNumber,
    address: data.address || '',
    address2: data.address2 || '',
    city: data.city || '',
    state: data.state || '',
    country: data.country || '',
    postalCode: data.postalCode || '',
    consumers: data.consumers,
    sites: data.sites,
    deletedAt: data.deletedAt,
  };
  return houseAccountFormDetails;
};

const transactionItemHelper = (transactions: TransactionItem[], currencyFormatter: (amount: string) => string) => {
  const products: TransactionItem[] = [];
  const mods: TransactionItem[] = [];
  transactions.forEach((t) => (t?.parentItemId ? mods : products).push(t));

  const items: TransactionItems[] = [];
  products.forEach((p) => {
    const item: TransactionItems = {
      itemName: p.productName,
      count: p.quantity.quantity.toString(),
      amount: currencyFormatter(p.actualAmount.amount.toString()),
      modifiers: [],
    };
    mods.forEach((m) => {
      if (p.id === m.parentItemId) {
        const mod: TransactionItemModifier = {
          modifierName: m.productName,
          amount:
            m.actualAmount.amount > 0
              ? `+${currencyFormatter(m.actualAmount.amount.toString())}`
              : currencyFormatter(m.actualAmount.amount.toString()),
        };
        item.modifiers.push(mod);
      }
    });
    items.push(item);
  });
  return items;
};

const transactionSubTaxHelper = (totals: TransactionTotals, currencyFormatter: (amount: string) => string) => {
  const subTaxes: TransactionSubTax[] = [];
  if (totals.totalTaxes.length > 0) {
    totals.totalTaxes.forEach((subTax) => {
      const tax: TransactionSubTax = {
        name: subTax.name,
        amount: `(${currencyFormatter(subTax.amount.amount.toString())})`,
      };
      subTaxes.push(tax);
    });
  }
  return subTaxes;
};

const guestTransactionItemHelper = (
  items: SalesResponses.TransactionDetailsItemDto[],
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionItemDto[] => {
  const pdfItems: SalesRequests.TransactionItemDto[] = [];
  if (items?.length > 0) {
    items.forEach((item) => {
      const pdfItemModifiers: SalesRequests.TransactionItemModifierDto[] = [];
      if (item.modifiers.length > 0) {
        item.modifiers.forEach((modifier) => {
          const pdfItemModifier: SalesRequests.TransactionItemModifierDto = {
            modifierName: modifier.name,
            amount: modifier.amount ? currencyFormatter(modifier.amount.toString()) : undefined,
          };
          pdfItemModifiers.push(pdfItemModifier);
        });
      }
      const pdfItem: SalesRequests.TransactionItemDto = {
        itemName: item.itemName,
        count: item.quantity,
        amount: item.isVoided
          ? `(${currencyFormatter(item.amount.toString())})`
          : currencyFormatter(item.amount.toString()),
        isVoided: item.isVoided,
        modifiers: pdfItemModifiers,
      };
      pdfItems.push(pdfItem);
    });
  }
  return pdfItems;
};

const guestTransactionSubTaxHelper = (
  subTaxes: SalesResponses.TransactionDetailsTaxDto[],
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionSubTaxDto[] => {
  const pdfSubTaxes: SalesRequests.TransactionSubTaxDto[] = [];
  if (subTaxes?.length > 0) {
    subTaxes.forEach((subTax) => {
      const pdfSubTax: SalesRequests.TransactionSubTaxDto = {
        name: subTax.taxName,
        amount: `${currencyFormatter(subTax.taxAmount.toString())}`,
        percent: `(${(+subTax.taxPercent * 100).toFixed(2)}%)`,
      };
      pdfSubTaxes.push(pdfSubTax);
    });
  }
  return pdfSubTaxes;
};

const guestTransactionSurchargeHelper = (
  surcharges: SalesResponses.TransactionDetailsSurchargeDto[],
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionSurchargeDto[] => {
  const pdfSurcharges: SalesRequests.TransactionSurchargeDto[] = [];
  if (surcharges?.length > 0) {
    surcharges.forEach((surcharge) => {
      const pdfSurcharge: SalesRequests.TransactionSurchargeDto = {
        name: surcharge.surchargeName,
        amount: `${currencyFormatter(surcharge.surchargeAmount.toString())}`,
      };
      pdfSurcharges.push(pdfSurcharge);
    });
  }
  return pdfSurcharges;
};

const guestTransactionDiscountHelper = (
  discounts: SalesResponses.TransactionDetailsDiscountDto[],
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionDiscountDto[] => {
  const pdfDiscounts: SalesRequests.TransactionDiscountDto[] = [];
  if (discounts?.length > 0) {
    discounts.forEach((discount) => {
      const pdfDiscount: SalesRequests.TransactionDiscountDto = {
        discountType: discount.discountType.toString(),
        discountName: discount.discountName,
        discountAmount: `(${currencyFormatter(discount.discountAmount.toString())})`,
      };
      pdfDiscounts.push(pdfDiscount);
    });
  }
  return pdfDiscounts;
};

const guestTransactionPaymentHelper = (
  payments: SalesResponses.TransactionDetailsPaymentDto[],
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionPaymentDto[] => {
  const pdfPayments: SalesRequests.TransactionPaymentDto[] = [];
  if (payments?.length > 0) {
    payments.forEach((payment) => {
      const pdfPayment: SalesRequests.TransactionPaymentDto = {
        tenderType: payment.tenderType,
        paymentMethodText: payment.tenderName,
        total: currencyFormatter(payment.tenderTotal.toString()),
        purchaseTotal: currencyFormatter(payment.tenderAmount.toString()),
        tipsGratuity: currencyFormatter((payment.tipAmount + (payment.gratuityAmount ?? 0)).toString()),
        cardInformation: payment.cardInformation ?? undefined,
        entryMethod: payment.entryMethod ?? undefined,
        creditCardType: payment.creditCardProvider ?? undefined,
      };
      pdfPayments.push(pdfPayment);
    });
  }
  return pdfPayments;
};

const saleTransactionSchemaMapper = (
  data: SaleTransaction,
  labels: TransactionLabels,
  accountCharged: string,
  dateFormatter: (date: Date) => string,
  currencyFormatter: (amount: string) => string
): CreateTransactionPDFBodySchema => {
  const itemsInfo = data.transactionItemsInfo as TransactionItem[];
  const totalsInfo = data.transactionTotalsInfo as TransactionTotals;
  const tipsGratuity = (totalsInfo.totals.gratuityAmount?.amount || 0) + (totalsInfo.totals.tipAmount?.amount || 0);
  const taxes =
    totalsInfo.totals.taxInclusive.amount !== 0
      ? totalsInfo.totals.taxInclusive.amount
      : totalsInfo.totals.taxExclusive.amount;
  const body: CreateTransactionPDFBodySchema = {
    labels,
    siteName: data.siteName,
    tableName: data.tableName,
    employeeName: data.employeeName,
    checkOpened: dateFormatter(data.checkOpenedDate),
    checkClosed: dateFormatter(data.checkClosedDate),
    guestCount: data.guestCount.toString(),
    items: transactionItemHelper(itemsInfo, currencyFormatter),
    subTotal: currencyFormatter(totalsInfo.totals.grossAmount.amount.toString()),
    tipsGratuity: currencyFormatter(tipsGratuity.toString()),
    taxes: currencyFormatter(taxes.toString()),
    subTaxes: transactionSubTaxHelper(totalsInfo, currencyFormatter),
    total: currencyFormatter(data.transactionAmount.toString()),
    purchaseTotal: currencyFormatter((totalsInfo.totals.grossAmount.amount + taxes).toString()),
    paymentMethodText: accountCharged,
    asJSON: true,
  };
  return body;
};

const safeValue = (field: string): string => field || '';
const safeNullableValue = (field: string | null): string => field || '';
const safeCurrencyValue = (field: string | undefined, currencyFormatter: (amount: string) => string) =>
  field ? currencyFormatter(field) : undefined;
const safeCurrencyString = (field: string | undefined, currencyFormatter: (amount: string) => string) =>
  safeCurrencyValue(field, currencyFormatter) ?? '';

const guestTransactionSchemaMapper = (
  data: SalesResponses.GetGuestTransactionDetailsResponseDto,
  labels: SalesRequests.TransactionPdfLabelsDto,
  dateFormatter: (date: string) => string,
  dateOnlyFormatter: (date: string) => string,
  currencyFormatter: (amount: string) => string
): SalesRequests.TransactionPdfDto => {
  const purchaseTotal = (Number(data?.subTotal) || 0) + (Number(data?.totalTax) || 0);
  const totalTax = purchaseTotal > 0 ? Number(data?.totalTax) || 0 : null;
  const body: SalesRequests.TransactionPdfDto = {
    labels,
    transactionNumber: data.receiptId,
    businessDate: dateOnlyFormatter(dayjs(data.businessDate).toString()),
    siteName: safeValue(data?.siteName),
    tableName: safeNullableValue(data?.tableName),
    terminalId: safeNullableValue(data?.terminalId),
    employeeName: safeValue(data?.employeeName),
    customerName: safeNullableValue(data?.customerName),
    checkOpened: dateFormatter(data.transactionOpenDate),
    checkClosed: dateFormatter(data.transactionCloseDate),
    guestCount: data?.guestCount ? data.guestCount.toString() : '',
    items: guestTransactionItemHelper(data.items, currencyFormatter),
    totalRefund: data?.totalRefund && data.totalRefund < 0 ? currencyFormatter(data.totalRefund.toString()) : undefined,
    subTotal: safeCurrencyString(data?.subTotal?.toString(), currencyFormatter),
    discounts: guestTransactionDiscountHelper(data.discounts, currencyFormatter),
    discountPromoAmount: data?.discountPromoTotal
      ? `-${currencyFormatter(data.discountPromoTotal.toString())}`
      : undefined,
    discountCompAmount: data?.discountCompTotal
      ? `-${currencyFormatter(data.discountCompTotal.toString())}`
      : undefined,
    discountSubTotal: safeCurrencyValue(data?.totalDiscount?.toString(), currencyFormatter),
    tipsGratuity: safeCurrencyString(data?.tipsGratuity?.toString(), currencyFormatter),
    taxes: safeCurrencyString(totalTax?.toString(), currencyFormatter),
    subTaxes: guestTransactionSubTaxHelper(data.taxes, currencyFormatter),
    total: currencyFormatter(data?.total?.toString() || '0'),
    purchaseTotal: currencyFormatter(purchaseTotal.toString()),
    paymentMethodText: data?.payments?.length > 0 ? data.payments[0].tenderName : '',
    payments: guestTransactionPaymentHelper(data.payments, currencyFormatter),
    totalSurcharge: safeCurrencyString(data?.totalSurcharge?.toString(), currencyFormatter),
    surcharges: guestTransactionSurchargeHelper(data.surcharges, currencyFormatter),
    asJSON: true,
  };
  return body;
};

// This returns a date range for the fiscal calendar where the index represents the desired period
const getFiscalCalendarDateRanges = (data: IPeriodFilter[] | undefined, index: number): GlobalFilterBarDateInput => {
  if (!data) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  const fiscalPeriod = data[index];

  return {
    startDate: dayjs((fiscalPeriod.current as IDateRange).startDate).format('YYYY-MM-DD'),
    endDate: getEndDate((fiscalPeriod.current as IDateRange).endDate),
  };
};

function getDate(preferences: UserPreferences | null | undefined) {
  return (value: string) => {
    if (!value) return null;

    // Format the time as 'UTC' is really just displaying the local time from the POS.
    // BQ returns the time as if it is UTC, but it is most likely not originally a UTC time.
    return `${formatDate(dayjs(value).toString(), preferences)} ${formatTime(
      dayjs(value).toString(),
      preferences,
      'UTC'
    )}`;
  };
}

function getDateOnly(preferences: UserPreferences | null | undefined) {
  return (value: string) => {
    if (!value) return null;

    return `${formatDate(dayjs(value).toString(), preferences)}`;
  };
}

function isBodyOverflowError(err: BodyOverflowError): boolean {
  // If the result set is too large to pass through the firebase functions layer,
  // then you either get a TooBigBody or a 500 depending on how much of an overflow
  // it is.
  return (
    !!err?.details?.fault?.detail?.errorcode?.includes('protocol.http.TooBigBody') ||
    !!err?.message?.includes('500 Error in get')
  );
}

function shouldRetry(failureCount: number, err: BodyOverflowError) {
  return !isBodyOverflowError(err);
}

// Method to get the number of GL accounts in the specified tree
const getNumberOfAccountsInTree = (glAccount: GlAccount): number => {
  let currentCount = 1;
  if (!glAccount.subAccounts.length) {
    return 1;
  }
  glAccount.subAccounts.forEach((childAccount) => {
    currentCount += getNumberOfAccountsInTree(childAccount);
  });
  return currentCount;
};

// Method to get number of all GL accounts passed in, including children.
const getNumberOfGlAccounts = (glAccounts: GlAccount[]): number => {
  let total = 0;
  glAccounts.forEach((account) => {
    total += getNumberOfAccountsInTree(account);
  });
  return total;
};

// Method to get all account ids from parent GL account to all children account if there are any
const getAllGlAccountIds = (glAccount: GlAccount): string[] => {
  let accountIds: string[] = [];
  accountIds.push(glAccount.accountId);
  if (glAccount.subAccounts.length) {
    glAccount.subAccounts.forEach((childAccount) => {
      accountIds = accountIds.concat(getAllGlAccountIds(childAccount));
    });
  }
  return accountIds;
};

// Separate method for update endpoint because parent account id is not returned
const getAllGlAccountIdsForUpdate = (glAccount: GlAccount): string[] => {
  const ids: string[] = getAllGlAccountIds(glAccount);
  return ids.filter((id) => id !== glAccount.accountId);
};

// Method returning if a GL account has subAccounts
const checkIfSubAccountsExist = (accounts: GlAccount[]) =>
  accounts.some((account) => account.subAccounts && account.subAccounts.length > 0);

// Method to check if form values contain a specific error message
const formErrorCheck = (errorMessage: string, value: string) => errorMessage.toLowerCase().includes(value);

// Helper method to generate proper query params for getGlAccount query
const toGetGlAccountsQueryParams = (
  body: GlAccountsRequest
): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
  const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
    { kind: 'multi', key: 'categoryIds', value: body.categoryIds.map((id) => id.toString()) },
    { kind: 'multi', key: 'accountTypes', value: body.accountTypes },
    { kind: 'multi', key: 'accountDetailTypes', value: body.accountDetailTypes },
  ];
  if (body.isActive === 'true' || body.isActive === 'false') {
    queryParams.push({ kind: 'single', key: 'isActive', value: body.isActive });
  }
  return queryParams;
};

// Helper method to update the isActive query key for getGlAccount endpoint call on status filter change
// If only active is checked, then query param for isActive key is 'true'
// If only inactive is checked, then query param for isActive key is 'false'
// Else if both or neither are checked, then query param for isActive key is '' which means it is not used in the endpoint call
const updateGlAccountsIsActiveFilter = (isActiveFilter: string[]) => {
  switch (JSON.stringify(isActiveFilter)) {
    case JSON.stringify([GlAccountsFilterIsActive.ACTIVE]):
      return 'true';
    case JSON.stringify([GlAccountsFilterIsActive.INACTIVE]):
      return 'false';
    default:
      return '';
  }
};

// Moving here to test
const getLineChartData = (selectedSeries: MuiLineChartData, xAxis: string[], startDate: string) => {
  // We only want to fix the y-axis data for the selected date range.
  // The comparison date range doesn't correspond to the dates on the x-axis so just needs to be a visual comparison.
  if (selectedSeries.chartLabel === 'Selected Range') {
    const dateOffset = Number(startDate.slice(-2));
    let n = 0;
    return xAxis.map((_, i) => {
      if (selectedSeries.data[n] && Number(selectedSeries.data[n].date.slice(-2)) - dateOffset === i) {
        const yValue = selectedSeries.data[n].y;
        n += 1;
        return yValue;
      }
      return null;
    });
  }
  // This is the function that previously was used for all series in the chart.
  // Now only needed for the comparison range.
  return xAxis.map((_, index) => (selectedSeries.data[index] ? selectedSeries.data[index].y : null));
};

// Moving here to test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTooltipData = (series: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series.map((seriesData: any) => {
    // This does a similar operation to the y-axis data formatting in LineChart.tsx.
    const { color } = seriesData;
    const yData = seriesData.additionalData;
    if (yData && !isNil(yData[0])) {
      if (yData[0].chartLabel === 'Selected Range') {
        const dateOffset = Number(yData[0].date.slice(-2));
        let n = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const arr = yData.map((_: any, i: number) => {
          if (!isNil(yData[n]) && Number(yData[n].date.slice(-2)) - dateOffset === i) {
            const yValue = yData[n];
            n += 1;
            return yValue;
          }
          return null;
        });
        return { yAxisData: arr, color };
      }
      if (yData[0].chartLabel === 'Comparison Range') {
        return { yAxisData: yData, color };
      }
    }
    return {};
  });

const SalesUtilities = {
  houseAccountMapper,
  houseAccountFormMapper,
  saleTransactionSchemaMapper,
  guestTransactionSchemaMapper,
  getDate,
  getDateOnly,
  getFiscalCalendarDateRanges,
  isBodyOverflowError,
  shouldRetry,
  getNumberOfAccountsInTree,
  getNumberOfGlAccounts,
  getAllGlAccountIds,
  getAllGlAccountIdsForUpdate,
  formErrorCheck,
  checkIfSubAccountsExist,
  toGetGlAccountsQueryParams,
  updateGlAccountsIsActiveFilter,
  getLineChartData,
  getTooltipData,
};

export default SalesUtilities;
