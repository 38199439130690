import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ApiError } from '..';
import { ApplicationModel } from 'src/types/application.type';

export type IApplicationsContext = {
    isLoading: boolean;
    isAppDialogOpen: boolean;
    setIsAppDialogOpen: Dispatch<SetStateAction<boolean>>;
    error: ApiError | null;
    application: ApplicationModel | undefined;
    userApplications: ApplicationModel[];
};

export const ApplicationsContext = createContext<IApplicationsContext>({} as IApplicationsContext);

export const useApplicationsContext = () => useContext(ApplicationsContext);
