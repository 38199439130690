import Button, { ButtonProps } from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
import { GlobalEmitter } from 'src/constants/global';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { RccEventKeys } from 'src/types';

export default function LogoutButton({
    onClick,
    ...props
}: Omit<ButtonProps, 'type' | 'onClick'> & { onClick?: () => void }) {
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslationNs();

    const handleLogout = async () => {
        if (onClick) onClick();
        await oktaAuth.signOut();
        GlobalEmitter.emit(RccEventKeys.SIGNOUT);
    };

    return (
        <Button {...props} type='button' onClick={handleLogout}>
            {t('COMMON.BUTTONS.LOGOUT')}
        </Button>
    );
}
