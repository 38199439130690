import { ReportMetric } from '@cbo/shared-library';
import { DashboardMetricConfiguration } from '@cbo/shared-library/request/admin.request';
import { KPIDataResponse, ReportMetricResult } from '../../sales/requests/requests';

export interface KPIConfigInfo {
  title: string;
  currency: boolean;
  percentage: boolean;
  positiveResultsPreferred: boolean;
}

export type KPIMap = {
  [key in ReportMetric]: KPIConfigInfo;
};

export interface KPIMappedResult extends KPIConfigInfo {
  value: number;
  change: number;
}

export interface KPIMappedResultConfigs {
  [key: string]: KPIMappedResult;
}

export const defaultKPIData = {
  title: 'admin.dashboardPage.KPICards.notFound',
  value: 0,
  change: 0,
  currency: false,
  percentage: false,
  isImprovement: false,
  arrowDirection: 'up',
  comparisonValue: 0,
  positiveResultsPreferred: false,
  comparisonRange: 'year',
};

export const KPI_CONFIG_MAP: KPIMap = {
  [ReportMetric.FORECAST_SALES]: {
    title: 'admin.dashboardPage.KPICards.forecastSales',
    currency: true,
    percentage: false,
    positiveResultsPreferred: true,
  },

  [ReportMetric.GUEST_CHECK_AVERAGE]: {
    title: 'admin.dashboardPage.KPICards.guestCheckAverage',
    currency: true,
    percentage: false,
    positiveResultsPreferred: true,
  },
  [ReportMetric.LABOR_PERCENTAGE]: {
    title: 'admin.dashboardPage.KPICards.laborPercentage',
    currency: false,
    percentage: true,
    positiveResultsPreferred: false,
  },
  [ReportMetric.NUMBER_OF_CHECKS]: {
    title: 'admin.dashboardPage.KPICards.numberOfChecks',
    currency: false,
    percentage: false,
    positiveResultsPreferred: true,
  },
  [ReportMetric.NUMBER_OF_GUESTS]: {
    title: 'admin.dashboardPage.KPICards.numberOfGuests',
    currency: false,
    percentage: false,
    positiveResultsPreferred: true,
  },
  [ReportMetric.SALES_PER_LABOR_HOUR]: {
    title: 'admin.dashboardPage.KPICards.salesPerLaborHour',
    currency: true,
    percentage: false,
    positiveResultsPreferred: true,
  },
  [ReportMetric.TOTAL_DISCOUNTS]: {
    title: 'admin.dashboardPage.KPICards.totalDiscounts',
    currency: true,
    percentage: false,
    positiveResultsPreferred: false,
  },
  [ReportMetric.TOTAL_VOIDS]: {
    title: 'admin.dashboardPage.KPICards.totalVoids',
    currency: true,
    percentage: false,
    positiveResultsPreferred: false,
  },
  [ReportMetric.NET_SALES]: {
    title: 'admin.dashboardPage.KPICards.netSales',
    currency: true,
    positiveResultsPreferred: false,
    percentage: false,
  },
};

export const mapKPIResponse = (
  response: KPIDataResponse,
  dashboardConfig: DashboardMetricConfiguration[]
): KPIMappedResultConfigs => {
  const dashboardConfiguration: KPIMappedResultConfigs = {};
  dashboardConfig.forEach((config) => {
    const data: ReportMetricResult | undefined = response.reportMetricResults[config.metric];
    const kpiInfo: KPIConfigInfo | undefined = KPI_CONFIG_MAP[config.metric];
    dashboardConfiguration[config.metric] = {
      title: kpiInfo?.title ?? defaultKPIData.title,
      currency: kpiInfo?.currency ?? defaultKPIData.currency,
      percentage: kpiInfo?.percentage ?? defaultKPIData.percentage,
      positiveResultsPreferred: kpiInfo?.positiveResultsPreferred ?? defaultKPIData.positiveResultsPreferred,
      value: data?.currentValue ?? defaultKPIData.value,
      change: data?.change ?? defaultKPIData.change,
    };
  });

  return dashboardConfiguration;
};

export const xAxisFormatter = (value: number, isSameDay: boolean, xAxis: string[]): string => {
  if (isSameDay) {
    if (value === 0) {
      return '12am';
    }
    if (value === 12) {
      return '12pm';
    }
    if (value > 12) {
      return (value - 12).toString();
    }
    return value.toString();
  }
  return xAxis[value];
};
