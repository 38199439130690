import { SiteInfo } from '@cbo/shared-library';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import siteSort from '../utils/siteUtils/siteSort';
import { useUsers } from './userContext';
import ContextProviderProps from '../models/ContextProviderProps';

const defaultSite: SiteInfo = {
  enterpriseUnitId: '',
  name: '',
  referenceId: '',
  timeZone: '',
};

const baseSites: Record<string, SiteInfo> = {
  default: defaultSite,
};

export type TSiteContext = {
  sites: Record<string, SiteInfo>;
  selectedSite: SiteInfo;
  updateSelectedSite: (site: SiteInfo) => void;
  multiSelectedSites: SiteInfo[];
  updateMultiSelectedSites: Dispatch<SetStateAction<SiteInfo[]>>;
  isSiteFiltering: boolean;
  updateIsSiteFiltering: Dispatch<SetStateAction<boolean>>;
};

export const SitesContext = createContext<TSiteContext>({
  sites: {},
  selectedSite: defaultSite,
  updateSelectedSite: (site) => {
    defaultSite.name = site.name;
    defaultSite.enterpriseUnitId = site.enterpriseUnitId;
    defaultSite.referenceId = site.referenceId;
    defaultSite.timeZone = site.timeZone;
  },
  multiSelectedSites: [],
  updateMultiSelectedSites: (_sites) => null,
  isSiteFiltering: false,
  updateIsSiteFiltering: () => null,
});

export const useSites = () => useContext(SitesContext);

export function SitesContextProvider({ children }: ContextProviderProps) {
  const users = useUsers();
  const [sites, setSites] = useState<Record<string, SiteInfo>>(baseSites);
  const [selectedSite, setSelectedSite] = useState<SiteInfo>(defaultSite);
  const [multiSelectedSites, updateMultiSelectedSites] = useState<SiteInfo[]>([]);
  const [isSiteFiltering, updateIsSiteFiltering] = useState<boolean>(false);

  const updateSelectedSite = useCallback(
    (site: SiteInfo) => {
      if (site && users.org?.bslId) {
        setSelectedSite(site);
        updateMultiSelectedSites([site]);
        if (site !== defaultSite)
          localStorage.setItem(`${users.id}-${users.org.bslId}-selectedSite`, JSON.stringify(site));
      }
    },
    [setSelectedSite, users.id, users.org?.bslId]
  );

  const providerValue = useMemo(
    () => ({
      sites,
      selectedSite,
      updateSelectedSite,
      multiSelectedSites,
      updateMultiSelectedSites,
      isSiteFiltering,
      updateIsSiteFiltering,
    }),
    [sites, selectedSite, updateSelectedSite, multiSelectedSites, isSiteFiltering]
  );

  useEffect(() => {
    if (users.isOrgSwitching) {
      updateSelectedSite(defaultSite);
    }
    if (users.bslAuth?.sites && !users.isOrgSwitching) {
      setSites(users.bslAuth.sites);
      const currentSite = localStorage.getItem(`${users.id}-${users.org?.bslId}-selectedSite`);
      if (currentSite) {
        const siteData: SiteInfo = JSON.parse(currentSite);
        if (
          siteData.enterpriseUnitId !== selectedSite.enterpriseUnitId &&
          Object.values(users.bslAuth.sites).some((site) => site.enterpriseUnitId === siteData.enterpriseUnitId)
        ) {
          updateSelectedSite(siteData);
        }
      } else {
        updateSelectedSite(siteSort(users.bslAuth.sites)[0]);
      }
    }
  }, [users.bslAuth?.sites, selectedSite, updateSelectedSite, users.id, users.org?.bslId, users.isOrgSwitching]);

  return <SitesContext.Provider value={providerValue}>{children}</SitesContext.Provider>;
}

export default SitesContextProvider;
