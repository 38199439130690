import Icon from '@mui/material/Icon';

export default function PointOfSaleWithDollarSignOutline() {
  return (
    <Icon>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.5 2H13.5C14.49 2 15.3 2.81 15.3 3.8V5.6C15.3 6.59 14.49 7.4 13.5 7.4H4.5C3.51 7.4 2.7 6.59 2.7 5.6V3.8C2.7 2.81 3.51 2 4.5 2ZM4.5 5.6H13.5V3.8H4.5V5.6ZM14.8597 9.33326C14.0279 9.6001 13.2638 10.0183 12.6 10.5555C12.6 10.5536 12.6 10.5518 12.6 10.55C12.6 10.298 12.402 10.1 12.15 10.1H11.25C10.998 10.1 10.8 10.298 10.8 10.55C10.8 10.802 10.998 11 11.25 11H12.101C11.8181 11.2773 11.5586 11.5783 11.3257 11.9H11.25C10.998 11.9 10.8 12.098 10.8 12.35C10.8 12.4452 10.8283 12.5328 10.8771 12.6048C10.3183 13.6104 10 14.768 10 16C10 16.1342 10.0038 16.2676 10.0112 16.4H0L3.123 9.371C3.411 8.723 4.059 8.3 4.77 8.3H13.23C13.9271 8.3 14.5636 8.70662 14.8597 9.33326ZM11.2547 20H1.8C0.81 20 0 19.19 0 18.2V17.3H10.1205C10.3062 18.2891 10.7 19.2048 11.2547 20ZM5.85 14.6H6.75C7.002 14.6 7.2 14.402 7.2 14.15C7.2 13.898 7.002 13.7 6.75 13.7H5.85C5.598 13.7 5.4 13.898 5.4 14.15C5.4 14.402 5.598 14.6 5.85 14.6ZM5.85 12.8H6.75C7.002 12.8 7.2 12.602 7.2 12.35C7.2 12.098 7.002 11.9 6.75 11.9H5.85C5.598 11.9 5.4 12.098 5.4 12.35C5.4 12.602 5.598 12.8 5.85 12.8ZM5.85 11H6.75C7.002 11 7.2 10.802 7.2 10.55C7.2 10.298 7.002 10.1 6.75 10.1H5.85C5.598 10.1 5.4 10.298 5.4 10.55C5.4 10.802 5.598 11 5.85 11ZM8.55 14.6H9.45C9.702 14.6 9.9 14.402 9.9 14.15C9.9 13.898 9.702 13.7 9.45 13.7H8.55C8.298 13.7 8.1 13.898 8.1 14.15C8.1 14.402 8.298 14.6 8.55 14.6ZM8.55 12.8H9.45C9.702 12.8 9.9 12.602 9.9 12.35C9.9 12.098 9.702 11.9 9.45 11.9H8.55C8.298 11.9 8.1 12.098 8.1 12.35C8.1 12.602 8.298 12.8 8.55 12.8ZM8.55 11H9.45C9.702 11 9.9 10.802 9.9 10.55C9.9 10.298 9.702 10.1 9.45 10.1H8.55C8.298 10.1 8.1 10.298 8.1 10.55C8.1 10.802 8.298 11 8.55 11Z'
          fill='#666666'
        />
        <circle cx='17' cy='16' r='6.5' stroke='#666666' />
        <path
          d='M17.1685 15.2792C15.9389 14.9596 15.5435 14.6292 15.5435 14.1146C15.5435 13.5242 16.0906 13.1125 17.006 13.1125C17.9702 13.1125 18.3277 13.5729 18.3602 14.25H19.5573C19.5194 13.3183 18.9506 12.4625 17.8185 12.1863V11H16.1935V12.17C15.1427 12.3975 14.2977 13.08 14.2977 14.1254C14.2977 15.3767 15.3323 15.9996 16.8435 16.3625C18.1977 16.6875 18.4685 17.1642 18.4685 17.6679C18.4685 18.0417 18.2031 18.6375 17.006 18.6375C15.8902 18.6375 15.4514 18.1392 15.3919 17.5H14.2002C14.2652 18.6863 15.1535 19.3525 16.1935 19.5746V20.75H17.8185V19.5854C18.8748 19.385 19.7144 18.7729 19.7144 17.6625C19.7144 16.1242 18.3981 15.5987 17.1685 15.2792Z'
          fill='#666666'
        />
      </svg>
    </Icon>
  );
}
