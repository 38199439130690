import { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { TextFieldElement, AutocompleteElement, SwitchElement } from 'react-hook-form-mui';
import { Controller, Control } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { GlAccountsSidebarState } from './GeneralLedgerAccountsSidebar';
import { GlAccountRows } from './GeneralLedgerAccountsManageReport';
import { GlAccountUpsertInfoResponse } from '../requests/requests';
import UnsavedFormDataPrompt from '../../components/UnsavedDataPrompt/UnsavedFormDataPrompt';

export type GeneralLedgerAccountsCreateEditProps = {
  control: Control | undefined;
  requestError: boolean;
  alertContent: string;
  accountTypeStatus: string;
  sidebarState: GlAccountsSidebarState;
  glAccount: GlAccountRows | undefined;
  upsertInfo: GlAccountUpsertInfoResponse | undefined;
  isDirty: boolean;
  isSubmitted: boolean;
};

function GeneralLedgerAccountsCreateEdit(props: GeneralLedgerAccountsCreateEditProps) {
  const {
    control,
    requestError,
    alertContent,
    accountTypeStatus,
    sidebarState,
    glAccount,
    upsertInfo,
    isDirty,
    isSubmitted,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();

  type Option = {
    id: number | string;
    label: string;
  };

  enum GlUpsertDropdownFields {
    Category,
    AccountType,
    AccountTypeDetail,
    ParentGlAccount,
  }

  const getDropdownValues = useCallback(
    (type: GlUpsertDropdownFields) => {
      if (upsertInfo) {
        const values: Option[] = [];
        switch (type) {
          case GlUpsertDropdownFields.Category:
            upsertInfo.categoryInfo.forEach((category) =>
              values.push({ id: category.categoryId, label: category.categoryName })
            );
            return values;
          case GlUpsertDropdownFields.AccountType:
            return Object.keys(upsertInfo.accountTypeInfo);
          case GlUpsertDropdownFields.AccountTypeDetail:
            if (accountTypeStatus && upsertInfo) {
              return upsertInfo.accountTypeInfo[accountTypeStatus];
            }
            return [];
          case GlUpsertDropdownFields.ParentGlAccount:
            upsertInfo.accountInfo.forEach((account) =>
              values.push({ id: account.accountNumber, label: `${account.accountNumber} - ${account.accountName}` })
            );
            return values;
          default:
            return [];
        }
      }
      return [];
    },
    [upsertInfo, accountTypeStatus, GlUpsertDropdownFields]
  );

  return (
    <Box sx={{ marginLeft: '24px' }} data-testid='gl-account-form-fields'>
      {requestError && (
        <Alert
          components={{ CloseIcon }}
          color='error'
          severity='error'
          sx={{ marginTop: '15px' }}
          data-testid='gl-request-alert'
        >
          {alertContent}
        </Alert>
      )}
      <UnsavedFormDataPrompt
        when={isDirty && !isSubmitted}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => isDirty && !isSubmitted}
      />
      <Typography variant='h5' sx={{ marginTop: 2 }}>
        {sidebarState === GlAccountsSidebarState.EDIT && glAccount ? (
          <Trans
            i18nKey='admin.glAccountConfiguration.actionContent.actionTitleWithGlAccount'
            values={{
              glAccountNumber: glAccount.accountNumber,
              glAccountName: glAccount.accountName,
              action: t('admin.glAccountConfiguration.actionContent.editTitle'),
            }}
          />
        ) : (
          t('admin.glAccountConfiguration.glAccountDrawer.newGlAccount')
        )}
      </Typography>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant='caption' color='secondary'>
          {t('admin.glAccountConfiguration.glAccountDrawer.required')}
        </Typography>
      </Box>
      <Controller
        name='isActive'
        key='isActive'
        control={control}
        render={({ field }) => (
          <SwitchElement
            id='active-input'
            name='isActive'
            label={
              field.value
                ? t('admin.glAccountConfiguration.generalLedgerAccounts.active')
                : t('admin.glAccountConfiguration.generalLedgerAccounts.inactive')
            }
            data-testid='active-input'
            sx={{ marginBottom: '8px' }}
          />
        )}
      />
      <Controller
        name='isDisplayedInInvoicing'
        key='isDisplayedInInvoicing'
        control={control}
        render={({ field }) => (
          <SwitchElement
            id='invoicing-input'
            name='isDisplayedInInvoicing'
            label={
              field.value
                ? t('admin.glAccountConfiguration.generalLedgerAccounts.showForInvoicing')
                : t('admin.glAccountConfiguration.generalLedgerAccounts.hideFromInvoicing')
            }
            data-testid='invoicing-input'
            sx={{ marginBottom: '8px' }}
          />
        )}
      />
      <TextFieldElement
        name='accountNumber'
        label={t('labor.glCode')}
        variant='standard'
        fullWidth
        data-testid='account-number-input'
        required
        sx={{ marginBottom: '8px' }}
      />
      <TextFieldElement
        name='accountName'
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glAccountName')}
        variant='standard'
        fullWidth
        data-testid='account-name-input'
        required
        sx={{ marginBottom: '8px' }}
      />
      <AutocompleteElement
        name='categoryId'
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glCategory')}
        textFieldProps={{
          variant: 'standard',
          id: 'category-input',
          sx: {
            marginBottom: '8px',
          },
        }}
        data-testid='category-input'
        required
        options={getDropdownValues(GlUpsertDropdownFields.Category)}
      />
      <AutocompleteElement
        name='accountType'
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glType')}
        textFieldProps={{ variant: 'standard', id: 'type-input', sx: { marginBottom: '8px' } }}
        data-testid='type-input'
        autocompleteProps={{
          isOptionEqualToValue: (option, value) => option === value,
        }}
        options={getDropdownValues(GlUpsertDropdownFields.AccountType)}
      />
      {accountTypeStatus && accountTypeStatus.length > 0 && (
        <AutocompleteElement
          name='accountTypeDetail'
          label={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType')}
          textFieldProps={{ variant: 'standard', id: 'detail-type-input', sx: { marginBottom: '8px' } }}
          data-testid='detail-type-input'
          required={!!accountTypeStatus && accountTypeStatus.length > 0}
          autocompleteProps={{
            isOptionEqualToValue: (option, value) => option === value,
          }}
          options={getDropdownValues(GlUpsertDropdownFields.AccountTypeDetail)}
        />
      )}
      <AutocompleteElement
        name='parentAccountNumber'
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.parentGlAccount')}
        textFieldProps={{ variant: 'standard', id: 'parent-account-input', sx: { marginBottom: '8px' } }}
        data-testid='parent-account-input'
        options={getDropdownValues(GlUpsertDropdownFields.ParentGlAccount)}
      />
      <TextFieldElement
        name='description'
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.description')}
        variant='standard'
        fullWidth
        data-testid='description-input'
      />
    </Box>
  );
}

export default GeneralLedgerAccountsCreateEdit;
