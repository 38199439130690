import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppConstants } from 'src/constants/app';
import enjson from './locales/en/en_US.json';

const i18nCommon = i18n.use(initReactI18next).createInstance(
    {
        resources: {
            en: {
                translation: enjson,
            },
        },
        ns: AppConstants.i18NS,
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    },
    (err) => {
        if (err) console.log(err);
    },
);

export default i18nCommon;
