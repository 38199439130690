import { StackProps } from '@mui/material';
import type { ReactNode } from 'react';
import { NavigationProps, useNavigation, NavigationProvider } from './NavigationContext';

export type NavigationMenuProps = {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
} & Partial<NavigationProps> &
  StackProps;

export function NavigationMenu(props: NavigationMenuProps) {
  const navigation = useNavigation();
  const { children, selection = navigation.selection, view = navigation.view } = props;

  return (
    <NavigationProvider selection={selection} view={view}>
      <ul
        style={{
          padding: 0,
        }}
      >
        {children}
      </ul>
    </NavigationProvider>
  );
}
