import { FilterRequestBody, FilterValuesResponse, MultiselectOption, ReportMetric, Req } from '@cbo/shared-library';
import { Sales as SalesRequestTypes } from '@cbo/shared-library/request';
import { Calendar, Sales as SalesResponses } from '@cbo/shared-library/response';
import axios, { AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import { Dictionary } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseGridRow } from '../../components/DataGrid/DataGrid';
import { useSites } from '../../contexts/siteContext';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useUsers } from '../../contexts/userContext';
import { EmployeeConfigurationsResponse } from '../../labor/services/employeeService';
import { IApiError } from '../../services/types';
import useCallBsl from '../../utils/hooks/useCallBsl';
import {
  BodyOverflowError,
  CreateStatementPDFBodySchema,
  CreateTransactionBodySchema,
  CreateTransactionPDFBodySchema,
  HouseAccountConsumer,
  HouseAccountRequestSchema,
  HouseAccountResponseSchema,
  HouseAccountSite,
  PatchStatementBodySchema,
  StatementBodySchema,
  StatementSchemaBase,
  UpdateHouseAccountRequestSchema,
} from '../types';
import SalesUtilities from '../utilities';

export const proxies = {
  houseAccounts: 'house-accounts',
  calendar: 'calendar',
  employeeConfiguration: 'employee-configuration',
  sales: 'sales',
  glAccounts: 'gl-accounts',
  siteManagement: 'site-management',
};
const Verb = Req.Firebase.HttpVerb;

export interface Id {
  id: number;
}

export interface BaseReportDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
}

export interface BaseReportComparisonDataRequest extends BaseReportDataRequest {
  previousStartDate: string;
  previousEndDate: string;
}

export interface HouseAccountSummary {
  houseAccountId: string;
  houseAccountName: string;
  isActive: boolean;
  customerId: string;
  lastTransactionAt: Date | null;
  lastStatementSentAt: Date | null;
  siteName: string | null;
  siteCount: number;
  deletedAt: Date | null;
  balance: string;
}

export interface HouseAccountSummaryPage {
  result: HouseAccountSummary[];
  count: number;
  pageNumber: number;
  rowsPerPage: number;
}

export interface ConsumerInfo {
  name: string;
  phone: string;
}

export interface HouseAccountActivity {
  activityId: string;
  activityType: string;
  amount: string;
  balance: string | null;
  createdAt: Date;
  employeeId: string | null;
  employeeName: string | null;
  houseAccountId: string;
  number: number | null;
  siteId: string | null;
  siteName: string | null;
  statementSentAt: Date | null;
  transactionTypeLabel: string | null;
  ticketNumber: string | null;
  statementNumber: number | null;
  consumerInfo: ConsumerInfo | null;
}

export interface HouseAccountActivitiesPage {
  result: (HouseAccountActivity & { consumerInfo: string })[];
  count: number;
  pageNumber: number;
  rowsPerPage: number;
}

export interface HouseAccount {
  houseAccountId: string;
  houseAccountName: string;
  houseAccountNumber: number;
  balance: string;
  isActive: boolean;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  maxBalance: number;
  deletedAt: Date;
  sites: HouseAccountSite[];
  consumers: HouseAccountConsumer[];
}

export interface HouseAccountStatement extends StatementSchemaBase {
  statementId: string;
  statementSentAt: Date | null;
}

export interface Transaction {
  employeeName: string;
  transactionAmount: string;
  paymentMethod: string;
  notes: string;
  transactionId: string;
}

export interface TransactionItem {
  id: string;
  parentItemId?: string;
  productName: string;
  actualAmount: {
    amount: number;
  };
  quantity: {
    quantity: number;
  };
}

export interface TransactionTotals {
  totals: {
    grossAmount: {
      amount: number;
    };
    tipAmount?: {
      amount: number;
    };
    gratuityAmount?: {
      amount: number;
    };
    grandAmount: {
      amount: number;
    };
    taxExclusive: {
      amount: number;
    };
    taxInclusive: {
      amount: number;
    };
  };
  totalTaxes: [
    {
      name: string;
      amount: {
        amount: number;
      };
    }
  ];
}

export interface SaleTransaction {
  transactionId: string;
  externalId: string;
  tenderType: string;
  transactionType: string;
  transactionTypeLabel: string;
  transactionAmount: string;
  transactionDateTime: Date;
  employeeId: string;
  employeeName: string;
  transactionTotalsInfo: TransactionTotals;
  transactionItemsInfo: TransactionItem[];
  startingBalance: string;
  endingBalance: string;
  businessDay: Date;
  consumerId: string;
  consumerName: string;
  customerId: string;
  customerName: string;
  siteId: string;
  siteName: string;
  isDeleted: boolean;
  notes: string;
  ticketNumber: string;
  receiptId: string;
  paymentMethod: string;
  localCurrency: string;
  companyName: string;
  tableName: string;
  checkOpenedDate: Date;
  checkClosedDate: Date;
  guestCount: number;
}

export interface PdfResponse {
  data: string;
}

export interface GuestCheckResponse {
  siteId: string;
  siteName: string;
  transactionId: string;
  employee: string;
  employeeId: string;
  customerName: string;
  tableName: string;
  terminalId: string;
  checkOpenTime: string;
  checkCloseTime: string;
  paymentName: string;
  amount: string;
  cardInformation: string;
  hasVoids: boolean;
  hasComps: boolean;
  hasPromos: boolean;
  hasRefunds: boolean;
}

export interface GuestCheckRequest extends BaseReportDataRequest {
  hasComps: string;
  hasPromos: string;
  hasRefunds: string;
  hasVoids: string;
}

export interface POSEventsRequest extends BaseReportDataRequest {
  employeeId: string[];
  terminalId: string[];
  eventType: string[];
}

export interface POSEventFiltersRequest {
  siteIds: string[];
}

export interface POSEventFiltersResponse {
  terminalId: MultiselectOption[];
  employee: MultiselectOption[];
}

export interface POSEventsResponse {
  siteId: string;
  siteName: string;
  employeeId: string;
  employee: string;
  terminalId: string;
  eventTime: string;
  eventType: string;
  details: string;
}

export interface ParentGlAccount {
  accountNumber: number;
  accountId: string;
}

export interface GlAccount {
  accountNumber: number;
  accountId: string;
  accountName: string;
  accountType: string;
  accountTypeDetail: string;
  categoryId: number;
  categoryName: string;
  description: string;
  parentGLAccount?: ParentGlAccount;
  isActive: boolean;
  isDisplayedInInvoicing: boolean;
  subAccounts: GlAccount[];
}

export interface GlAccountsResponse {
  accounts: GlAccount[];
}

export interface GlAccountFiltersResponse {
  categoryInfo: {
    categoryId: number;
    categoryName: string;
  }[];
  accountTypes: string[];
  accountDetailTypes: string[];
}

export interface GlAccountInfo {
  accountNumber: number;
  accountName: string;
}

export interface GlAccountName {
  glAccountId: string;
  glAccountName: string;
}

export interface GlCategoryInfo {
  categoryId: number;
  categoryName: string;
}

export interface GlAccountUpsertInfoResponse {
  accountInfo: GlAccountInfo[];
  accountTypeInfo: Record<string, string[]>;
  categoryInfo: GlCategoryInfo[];
}

export interface GlAccountsRequest {
  isActive: string;
  categoryIds: number[];
  accountTypes: string[];
  accountDetailTypes: string[];
}

export interface CreateGlAccountRequest {
  accountNumber: number;
  accountName: string;
  categoryId: number;
  accountType?: string;
  accountTypeDetail?: string;
  parentAccountNumber?: number;
  description?: string;
  isActive: boolean;
  isDisplayedInInvoicing: boolean;
}

export interface CreateGlAccountsResponse {
  accountId: string;
}

export interface GlAccountsDeleteRequest {
  accountIds: string[];
}

export interface UpdateGlAccountRequest extends CreateGlAccountRequest {
  isActiveAffectedAccountIds?: string[];
}

export interface UpdateIsActiveGlAccountsRequest {
  isActive: boolean;
  isActiveAffectedAccountIds: string[];
}

export interface DashboardGraphDataRequest {
  startDate: string;
  endDate: string;
}

export interface DashboardGraphDataResponse {
  xAxis: string[];
  netSales: number[];
  comparisonNetSales: number[];
  forecastedNetSales: number[];
}

export interface KPIDataRequest {
  startDate: string;
  endDate: string;
  currentDate: string;
  metricsRequested: ReportMetric[];
}

export interface SalesSummaryDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
}

export interface ProductMixMenuGridDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  categories?: string[];
  items?: string[];
}

export interface ProductMixTop5ItemsRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  categories?: string[];
  items?: string[];
}

export interface ProductMixTotalSalesRequest {
  startDate: string;
  endDate: string;
  previousStartDate: string;
  previousEndDate: string;
  selectionType: string;
  siteIds: string[];
  categories?: string[];
  items?: string[];
}

export interface ProductMixEmployeeSalesDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  categories?: string[];
  items?: string[];
  employees?: string[];
}

export interface TransactionDetailsExportDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
}

export interface RefundsGridDataRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  employees?: string[];
}

export interface RefundsCardsDataRequest {
  startDate: string;
  endDate: string;
  previousStartDate: string;
  previousEndDate: string;
  siteIds: string[];
  employees?: string[];
}

export interface ReportMetricResult {
  currentValue: number;
  change: number;
}

export interface KPIDataResponse {
  isSuccess: boolean;
  reportMetricResults: Dictionary<ReportMetricResult>;
}

export interface ProductMixMenuGridResults extends BaseGridRow {
  hierarchy: string[];
  soldQuantity: number;
  modifierQuantity: number;
  itemSales: number;
  totalParentSales: number;
  percentOfCategory: number;
  percentOfParent: number;
  percentOfTotal: number;
  totalParentPercent: number;
  id: number;
}

export interface ProductMixItemResult {
  itemName: string;
  netSales: number;
  itemCount: number;
}

export interface ProductMixTotalSalesDataPoint {
  netSales: number;
  itemCount: number;
  dateLabel: string;
  date: string;
}

export interface ProductMixSellerResult {
  netSales: number;
  itemCount: number;
  employeeName: string;
}

export interface ProductMixEmployeeGridResults {
  hierarchy: string[];
  soldQuantity: number;
  itemSales: number;
  id: number;
}
export interface ProductMixMenuGridDataResponse {
  results: ProductMixMenuGridResults[];
}
export interface ProductMixTop5ItemsResponse {
  items: ProductMixItemResult[];
}
export interface ProductMixTop5SellersResponse {
  topSellers: ProductMixSellerResult[];
}
export interface ProductMixTotalSalesResponse {
  selectedDateRange: ProductMixTotalSalesDataPoint[];
  comparisonDateRange: ProductMixTotalSalesDataPoint[];
}

export interface ProductMixEmployeeSalesGridResponse {
  results: ProductMixEmployeeGridResults[];
}

export interface SalesSummaryGridResults extends BaseGridRow {
  hierarchy: string[];
  id: number;
  quantity: number;
  total: number;
}

export interface SalesPaymentGridResults extends BaseGridRow {
  hierarchy: string[];
  id: number;
  quantity: number;
  amount: number;
  tipsGratuities: number;
  total: number;
}

export interface RefundDataGridResults extends BaseGridRow {
  id: number;
  businessDate: string;
  siteName: string;
  employeeName: string;
  receiptId: string;
  closeTimestamp: string;
  tenderType: string;
  refundAmount: number;
}

export interface SalesSummaryGridResponseDto {
  results: SalesSummaryGridResults[];
}
export interface SalesPaymentsGridResponseDto {
  results: SalesPaymentGridResults[];
}

export interface SalesSummaryDataResponse {
  netSales: number;
  discounts: number;
  charges: number;
  taxes: number;
  grossSales: number;
}

export interface RefundCardsDataResponse {
  refundedCount: number;
  refundAmount: number;
  refundCountComparisonPercent: number;
  refundAmountComparisonPercent: number;
}

export interface RefundDataGridResponse {
  results: RefundDataGridResults[];
}

export interface SalesDefinitionsSettings extends Id {
  organizationId: string;
  includeExclusiveTaxes: boolean;
  includeCharges: boolean;
  includeVoids: boolean;
  includePromotions: boolean;
  includeDiscounts: boolean;
}

export interface UpdateSalesDefinitionsSettings {
  includeDiscounts?: boolean;
  includeExclusiveTaxes?: boolean;
  includeCharges?: boolean;
  includeVoids?: boolean;
  includePromotions?: boolean;
}
export interface VoidsReportCommonRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  voidReasons?: string[];
  employees?: string[];
}

export interface VoidsReportGridResult extends BaseGridRow {
  dateOfBusiness: string;
  site: string;
  employeeName: string;
  approvedBy: string;
  transactionNumber: string;
  dateOrTime: string;
  voidReason: string;
  voidItem: string;
  voidCount: number;
  voidAmount: number;
  id: number;
}

export interface VoidsReportGridDataResponse {
  results: VoidsReportGridResult[];
}

export interface VoidReasonResult {
  voidReason: string;
  voidAmount: number;
  voidCount: number;
  percentOfTotal: number;
}

export interface VoidsReportTop5ReasonsDataResponse {
  voidReasons: VoidReasonResult[];
}

export interface VoidsReportKPICardsDataRequest extends VoidsReportCommonRequest {
  previousStartDate: string;
  previousEndDate: string;
}

export interface VoidsReportKPICardsDataResponse {
  totalVoidsCount: number;
  voidsCountPercentChange: number;
  totalVoidsAmount: number;
  voidsAmountPercentChange: number;
}

export interface TaxesRequestDto {
  startDate: string;
  endDate: string;
  siteIds: string[];
  taxNames?: string[];
}

export interface TotalTaxesResponse {
  totalTaxAmount: number;
}

export interface TaxesGridDataPoint extends BaseGridRow {
  taxName: string;
  id: number;
  taxType: string;
  taxRate: number;
  salesAmount: number;
  taxCollected: number;
}

export interface TaxesGridResponse {
  results: TaxesGridDataPoint[];
}

export interface TaxDataPoint {
  name: string;
  totalTaxAmount: number;
  percentOfTotalTax: number;
}

export interface TaxesByTypeResponse {
  results: TaxDataPoint[];
}

export interface DiscountReportCommonRequest {
  startDate: string;
  endDate: string;
  siteIds?: string[];
  discountNames?: string[];
  employees?: string[];
  approvedBy?: string[];
}

export interface DiscountReportGridDataRow extends BaseGridRow {
  id: number;
  dateOfBusiness: string;
  site: string;
  employeeName: string;
  approvedBy: string;
  transactionNumber: string;
  dateOrTime: string;
  discountType: string;
  discountName: string;
  discountCount: number;
  discountAmount: number;
}

export interface DiscountReportGridDataResponse {
  results: DiscountReportGridDataRow[];
}

export interface DiscountReportKPICardsRequest extends DiscountReportCommonRequest {
  previousStartDate: string;
  previousEndDate: string;
}

export interface DiscountReportKPICardsResponse {
  countOfDiscounts: number;
  discountCountPercentChange: number;
  totalAmountOfDiscounts: number;
  discountAmountPercentChange: number;
}

export interface DiscountItemData {
  discountName: string;
  discountAmount: number;
  numberOfDiscounts: number;
  percentOfTotal: number;
}

export interface DiscountReportTop5DiscountsResponse {
  discountItems: DiscountItemData[];
}

export interface TransactionDetailsExportGridResults {
  id: number;
  businessDate: string;
  siteName: string;
  employeeName: string;
  transactionId: string;
  transactionOpenDateTime: string;
  transactionCloseDateTime: string;
  dayPart: string;
  revenueCenter: string;
  orderMode: string[];
  orderSource: string;
  orderDestinations: string[];
  paymentName: string[];
  isRefund: string;
  tableName: string;
  guestCount: number;
  itemCount: number;
  netSales: number;
  grossSales: number;
  voidAmount: number;
  promoAmount: number;
  compAmount: number;
  otherCharges: number;
  tipAmount: number;
  taxAmount: number;
}

export interface TransactionDetailsExportGridResponseDto {
  response: TransactionDetailsExportGridResults[];
}

export interface RevenueCenterCommonRequest {
  startDate: string;
  endDate: string;
  siteIds: string[];
  dayparts?: string[];
  revenueCenters?: string[];
}

export interface PerPersonAverageByRevenueCenterResults {
  revenueCenter: string;
  netSales: number;
  perPersonAverage: number;
  guestCount: number;
}

export interface PerPersonAverageByRevenueCenterResponse {
  results: PerPersonAverageByRevenueCenterResults[];
}

export interface SalesByRevenueCenterResults {
  revenueCenter: string;
  netSales: number;
  transactionCount: number;
  guestCount: number;
}

export interface SalesByRevenueCenterResponse {
  results: SalesByRevenueCenterResults[];
}

export interface RevenueCenterGridResults extends BaseGridRow {
  id: number;
  hierarchy: string[];
  totalNetSales: number;
  totalTaxAmount: number;
  totalDiscountAmount: number;
  totalSurchargeAmount: number;
  totalGrossSales: number;
  transactionCount: number;
  totalGuestCount: number;
  perPersonAverage: number;
  transactionAverage: number;
}

export interface RevenueCenterGridResponse {
  results: RevenueCenterGridResults[];
}

export interface ProfitLossGridDataRequest {
  startDate: string;
  endDate: string;
  comparisonStartDate: string;
  comparisonEndDate: string;
  siteIds: string[];
}

export interface AmountAndPercentage {
  amount: number;
  percentOfSales: number;
}

export interface ProfitLossGridData extends BaseGridRow {
  hierarchy: string[];
  id: number;
  currentPeriodTotal: number;
  currentPeriodPercentage: number;
  comparisonPeriodTotal: number;
  comparisonPeriodPercentage: number;
}

export interface ProfitLossPageResponse {
  results: ProfitLossGridData[];
  sales: AmountAndPercentage;
  costOfGoods: AmountAndPercentage;
  costOfLabor: AmountAndPercentage;
  grossMargin: AmountAndPercentage;
}

export interface PaymentsByTypeResults {
  transactionCount: number;
  paymentAmount: number;
  paymentAmountPercentage: number;
  paymentName: string;
}

export interface PaymentsByTypeResponseDto {
  results: PaymentsByTypeResults[];
}

export interface PaymentsGridResults extends BaseGridRow {
  dateOfBusiness: string;
  id: number;
  siteName: string;
  employee: string;
  receiptId: string;
  transactionId: string;
  timestamp: string;
  paymentType: string;
  paymentName: string;
  paymentAmount: number;
  tipAmount: number;
}

export interface PaymentsGridResponseDto {
  results: PaymentsGridResults[];
}

export interface PaymentsRequestDto extends BaseReportDataRequest {
  paymentTypes?: string[];
  employees?: string[];
}

export interface PaymentsComparisonRequestDto extends BaseReportComparisonDataRequest {
  paymentTypes?: string[];
  employees?: string[];
}

export interface TotalPaymentsResponseDto {
  paymentsAmount: number;
  comparisonPaymentsAmount: number;
  amountComparisonPercentage: number;
}

export interface TotalSalesByShiftResponseDto {
  employeeConfigId: string;
  netTotalSales: number;
  shiftId: string;
}

export type SalesRequests = {
  getHouseAccount: (houseAccountId: string) => Promise<HouseAccount | undefined>;
  getHouseAccountsSummary: () => Promise<HouseAccountSummary[]>;
  createHouseAccount: (body: HouseAccountRequestSchema) => Promise<HouseAccountResponseSchema | undefined>;
  getHouseAccountsActivities: (houseAccountId: string) => Promise<HouseAccountActivity[]>;
  putHouseAccount: (houseAccountId: string, body: HouseAccountRequestSchema) => Promise<HouseAccount | undefined>;
  getStatement: (statementId: string) => Promise<HouseAccountStatement>;
  createStatement: (houseAccountId: string, body: StatementBodySchema) => Promise<HouseAccountStatement | undefined>;
  createStatementPDF: (body: CreateStatementPDFBodySchema) => Promise<string>;
  deleteStatement: (statementId: string) => Promise<boolean>;
  patchStatement: (statementId: string, body: PatchStatementBodySchema) => Promise<boolean>;
  patchHouseAccount: (houseAccountId: string, body: UpdateHouseAccountRequestSchema) => Promise<boolean>;
  deleteHouseAccount: (houseAccountId: string) => Promise<boolean>;
  restoreHouseAccount: (houseAccountId: string) => Promise<boolean>;
  createTransaction: (houseAccountId: string, body: CreateTransactionBodySchema) => Promise<Transaction>;
  getTransaction: (transactionId: string) => Promise<Transaction | undefined>;
  getSaleTransaction: (transactionId: string) => Promise<SaleTransaction | undefined>;
  getGuestTransaction: (
    transactionId: string,
    businessDate: string
  ) => Promise<SalesResponses.GetGuestTransactionDetailsResponseDto | undefined>;
  createPdf: (
    body: CreateTransactionPDFBodySchema | SalesRequestTypes.TransactionPdfDto
  ) => Promise<string | undefined>;
  getKPIData: (body: KPIDataRequest) => Promise<KPIDataResponse>;
  getSalesSummaryCardData: (body: SalesSummaryDataRequest) => Promise<SalesSummaryDataResponse>;
  getDashboardGraphData: (body: DashboardGraphDataRequest) => Promise<DashboardGraphDataResponse>;
  getKPIData2: (body: KPIDataRequest) => Promise<KPIDataResponse>;
  getCalendarFilters: () => Promise<Calendar.FiscalCalendarFilters | undefined>;
  getEmployeeGeneralInfoUrl: (posEmployeeId: string) => Promise<string | undefined>;
  getAllGuestChecks: (body: GuestCheckRequest) => Promise<GuestCheckResponse[]>;
  getPOSEventFilters: (body: POSEventFiltersRequest) => Promise<POSEventFiltersResponse>;
  getPOSEvents: (body: POSEventsRequest) => Promise<POSEventsResponse[]>;
  getProductMixMenuGridData: (body: ProductMixMenuGridDataRequest) => Promise<ProductMixMenuGridDataResponse>;
  getProductMixTop5Items: (body: ProductMixTop5ItemsRequest) => Promise<ProductMixTop5ItemsResponse>;
  getProductMixTotalSales: (body: ProductMixTotalSalesRequest) => Promise<ProductMixTotalSalesResponse>;
  getProductMixTop5Sellers: (body: ProductMixEmployeeSalesDataRequest) => Promise<ProductMixTop5SellersResponse>;
  getProductMixEmployeeSalesGrid: (
    body: ProductMixEmployeeSalesDataRequest
  ) => Promise<ProductMixEmployeeSalesGridResponse>;
  getFilterValues: (body: FilterRequestBody) => Promise<FilterValuesResponse>;
  getGlAccounts: (body: GlAccountsRequest) => Promise<GlAccountsResponse | undefined>;
  getGlAccountFilters: () => Promise<GlAccountFiltersResponse | undefined>;
  getGlAccountUpsertInfo: () => Promise<GlAccountUpsertInfoResponse | undefined>;
  createGlAccount: (body: CreateGlAccountRequest) => Promise<CreateGlAccountsResponse>;
  deleteGlAccount: (body: GlAccountsDeleteRequest) => Promise<boolean>;
  updateGlAccount: (glAccountId: string, body: UpdateGlAccountRequest) => Promise<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadGlAccounts: (file: File) => Promise<AxiosResponse<any, any>>;
  restoreDeleteGlAccount: (body: GlAccountsDeleteRequest) => Promise<boolean>;
  updateIsActiveGlAccounts: (body: UpdateIsActiveGlAccountsRequest) => Promise<boolean>;
  getAllGlAccountNames: () => Promise<GlAccountName[] | undefined>;
  getGlAccountById: (glAccountId: string) => Promise<GlAccount | void>;
  getSalesDefinitionsSettings: () => Promise<SalesDefinitionsSettings>;
  updateSalesDefinitionsSettings: (body: UpdateSalesDefinitionsSettings) => Promise<Id>;
  getSalesSummaryGridData: (body: SalesSummaryDataRequest) => Promise<SalesSummaryGridResponseDto>;
  getSalesSummaryPaymentsGridData: (body: SalesSummaryDataRequest) => Promise<SalesPaymentsGridResponseDto>;
  getProfitLossGridData: (body: ProfitLossGridDataRequest) => Promise<ProfitLossPageResponse>;
  getRefundsGridData: (body: RefundsGridDataRequest) => Promise<RefundDataGridResponse>;
  getRefundsCardsData: (body: RefundsCardsDataRequest) => Promise<RefundCardsDataResponse>;
  getTotalTaxesData: (body: TaxesRequestDto) => Promise<TotalTaxesResponse>;
  getTaxesGridData: (body: TaxesRequestDto) => Promise<TaxesGridResponse>;
  getTaxesByTypeData: (body: TaxesRequestDto) => Promise<TaxesByTypeResponse>;
  getDiscountReportKPICardsData: (body: DiscountReportKPICardsRequest) => Promise<DiscountReportKPICardsResponse>;
  getDiscountReportTop5DiscountsData: (
    body: DiscountReportCommonRequest
  ) => Promise<DiscountReportTop5DiscountsResponse>;
  getDiscountReportGridData: (body: DiscountReportCommonRequest) => Promise<DiscountReportGridDataResponse>;
  getSalesByRevenueCenterData: (body: RevenueCenterCommonRequest) => Promise<SalesByRevenueCenterResponse>;
  getPerPersonAverageByRevenueCenterData: (
    body: RevenueCenterCommonRequest
  ) => Promise<PerPersonAverageByRevenueCenterResponse>;
  getRevenueCenterGridData: (body: RevenueCenterCommonRequest) => Promise<RevenueCenterGridResponse>;
  getVoidsReportGridData: (body: VoidsReportCommonRequest) => Promise<VoidsReportGridDataResponse>;
  getVoidsReportTop5ReasonsData: (body: VoidsReportCommonRequest) => Promise<VoidsReportTop5ReasonsDataResponse>;
  getVoidsReportKPICardsData: (body: VoidsReportKPICardsDataRequest) => Promise<VoidsReportKPICardsDataResponse>;
  getTransactionsDetailsExportGridData: (
    body: TransactionDetailsExportDataRequest
  ) => Promise<TransactionDetailsExportGridResponseDto>;
  getTotalPaymentsData: (body: PaymentsComparisonRequestDto) => Promise<TotalPaymentsResponseDto>;
  getPaymentsByTypeData: (body: PaymentsRequestDto) => Promise<PaymentsByTypeResponseDto>;
  getPaymentsGridData: (body: PaymentsRequestDto) => Promise<PaymentsGridResponseDto>;
  getDaypartFiltersBslRequest: (siteIds: string[]) => Promise<FilterValuesResponse>;
  getTotalSalesByShift: (date: string) => Promise<TotalSalesByShiftResponseDto[]>;
};

const useSalesRequests = (): SalesRequests => {
  const { t } = useTranslation();
  const { setSnackbarState } = useSnackbar();
  const { selectedSite } = useSites();
  const callBsl = useCallBsl();
  const user = useUsers();

  const throwBspError = useCallback(
    (err: unknown) => {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(err);

      setSnackbarState({
        open: true,
        message: t('sales.errors.genericMessage'),
        color: 'error',
      });
      return err;
    },
    [t, setSnackbarState]
  );

  const getFilterValues = useCallback(
    async (body: FilterRequestBody) =>
      callBsl<FilterValuesResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['filters'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getDaypartFiltersBslRequest = useCallback(
    async (siteIds: string[]) =>
      callBsl<FilterValuesResponse>({
        proxy: proxies.siteManagement,
        verb: Verb.POST,
        pathSegments: ['sites', 'daypart-filters'],
        payload: siteIds,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getHouseAccountsSummary = useCallback(
    async (): Promise<HouseAccountSummary[]> =>
      callBsl<HouseAccountSummaryPage>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts'],
      })
        .then((res: HouseAccountSummaryPage): HouseAccountSummary[] => res.result)
        .catch((err) => {
          throwBspError(err);
          return [];
        }),
    [throwBspError, callBsl]
  );

  const createHouseAccount = useCallback(
    async (body: HouseAccountRequestSchema): Promise<HouseAccountResponseSchema | undefined> =>
      callBsl<HouseAccountResponseSchema | undefined>({
        proxy: proxies.houseAccounts,
        verb: Verb.POST,
        pathSegments: ['house-accounts'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      }).catch((err) => {
        const error = err as IApiError;
        if (error.details?.message?.includes('houseAccountName must be unique')) {
          throw err;
        }
        throwBspError(err);
        return undefined;
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const getHouseAccountsActivities = useCallback(
    async (houseAccountId: string): Promise<HouseAccountActivity[]> =>
      callBsl<HouseAccountActivitiesPage>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts', houseAccountId, 'activities'],
      })
        .then((res: HouseAccountActivitiesPage): HouseAccountActivity[] =>
          res.result.map((activity) => {
            const consumerInfo = activity.consumerInfo ? JSON.parse(activity.consumerInfo) : undefined;
            return { ...activity, consumerInfo };
          })
        )
        .catch((err) => {
          throwBspError(err);
          return [];
        }),
    [throwBspError, callBsl]
  );

  const getHouseAccount = useCallback(
    async (houseAccountId: string): Promise<HouseAccount | undefined> =>
      callBsl<HouseAccount>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts', houseAccountId],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const putHouseAccount = useCallback(
    async (houseAccountId: string, body: HouseAccountRequestSchema): Promise<HouseAccount | undefined> =>
      callBsl<HouseAccount>({
        proxy: proxies.houseAccounts,
        verb: Verb.PUT,
        pathSegments: ['house-accounts', houseAccountId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      }).catch((err) => {
        const error = err as IApiError;
        if (error?.details?.message?.includes('houseAccountName must be unique')) {
          throw err;
        }
        throwBspError(err);
        return undefined;
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const createStatement = useCallback(
    async (houseAccountId: string, body: StatementBodySchema): Promise<HouseAccountStatement | undefined> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.POST,
        pathSegments: ['house-accounts', houseAccountId, 'statements'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const deleteStatement = useCallback(
    async (statementId: string): Promise<boolean> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.DELETE,
        pathSegments: ['house-accounts', 'statements', statementId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const patchStatement = useCallback(
    async (statementId: string, body: PatchStatementBodySchema) =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.PATCH,
        pathSegments: ['house-accounts', 'statements', statementId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const getStatement = useCallback(
    async (statementId: string): Promise<HouseAccountStatement> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts', 'statements', statementId],
      }),
    [callBsl]
  );

  const createStatementPDF = useCallback(
    async (body: CreateStatementPDFBodySchema) =>
      callBsl<PdfResponse>({
        proxy: proxies.houseAccounts,
        verb: Verb.POST,
        pathSegments: ['house-accounts', 'statements', 'pdf'],
        payload: body,
      }).then((res) => {
        const buffer = Buffer.from(res.data);
        const blob = new Blob([buffer], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
      }),
    [callBsl]
  );

  const patchHouseAccount = useCallback(
    async (houseAccountId: string, body: UpdateHouseAccountRequestSchema): Promise<boolean> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.PATCH,
        pathSegments: ['house-accounts', houseAccountId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const deleteHouseAccount = useCallback(
    async (houseAccountId: string): Promise<boolean> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.DELETE,
        pathSegments: ['house-accounts', houseAccountId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const restoreHouseAccount = useCallback(
    async (houseAccountId: string): Promise<boolean> =>
      callBsl<HouseAccountStatement>({
        proxy: proxies.houseAccounts,
        verb: Verb.POST,
        pathSegments: ['house-accounts', houseAccountId, 'restore'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const createTransaction = useCallback(
    async (houseAccountId: string, body: CreateTransactionBodySchema): Promise<Transaction> =>
      callBsl<Transaction>({
        proxy: proxies.houseAccounts,
        verb: Verb.POST,
        pathSegments: ['house-accounts', houseAccountId, 'transactions'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: body,
      }),
    [selectedSite.enterpriseUnitId, callBsl]
  );

  const getTransaction = useCallback(
    async (transactionId: string): Promise<Transaction | undefined> =>
      callBsl<Transaction>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts', 'transactions', transactionId],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const getGuestTransaction = useCallback(
    async (
      transactionId: string,
      businessDate: string
    ): Promise<SalesResponses.GetGuestTransactionDetailsResponseDto | undefined> =>
      callBsl<SalesResponses.GetGuestTransactionDetailsResponseDto>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['guest-checks', businessDate, transactionId],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const getSaleTransaction = useCallback(
    async (transactionId: string): Promise<SaleTransaction | undefined> =>
      callBsl<SaleTransaction>({
        proxy: proxies.houseAccounts,
        verb: Verb.GET,
        pathSegments: ['house-accounts', 'transactions', transactionId],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const createPdf = useCallback(
    async (body: CreateTransactionPDFBodySchema | SalesRequestTypes.TransactionPdfDto) =>
      callBsl<PdfResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['transactions', 'pdf'],
        payload: body,
      })
        .then((res) => {
          const buffer = Buffer.from(res.data);
          const blob = new Blob([buffer], { type: 'application/pdf' });
          return URL.createObjectURL(blob);
        })
        .catch((err) => {
          throwBspError(err);
          return undefined;
        }),
    [throwBspError, callBsl]
  );

  const handleOverflowError = useCallback(
    (err: BodyOverflowError) => {
      if (SalesUtilities.isBodyOverflowError(err)) {
        throw err;
      }

      throwBspError(err);
      return [];
    },
    [throwBspError]
  );

  const getAllGuestChecks = useCallback(
    async (body: GuestCheckRequest) =>
      callBsl<GuestCheckResponse[]>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['guest-checks'],
        queryParams: [
          { kind: 'multi', key: 'siteIds', value: body.siteIds },
          { kind: 'single', key: 'startDate', value: body.startDate },
          { kind: 'single', key: 'endDate', value: body.endDate },
          { kind: 'single', key: 'hasComps', value: body.hasComps },
          { kind: 'single', key: 'hasPromos', value: body.hasPromos },
          { kind: 'single', key: 'hasRefunds', value: body.hasRefunds },
          { kind: 'single', key: 'hasVoids', value: body.hasVoids },
        ],
      }).catch(handleOverflowError),
    [handleOverflowError, callBsl]
  );

  const getPOSEventFilters = useCallback(
    async (body: POSEventFiltersRequest) =>
      callBsl<POSEventFiltersResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['filters', 'pos-event-log'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          terminalId: [],
          employee: [],
        } as POSEventFiltersResponse;
      }),
    [throwBspError, callBsl]
  );

  const getPOSEvents = useCallback(
    async (body: POSEventsRequest) =>
      callBsl<POSEventsResponse[]>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['pos-events'],
        payload: body,
      }).catch(handleOverflowError),
    [handleOverflowError, callBsl]
  );

  const getKPIData = useCallback(
    async (body: KPIDataRequest) =>
      callBsl<KPIDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['dashboard', 'kpi'],
        payload: body,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      }).catch((err) => {
        throwBspError(err);
        return {
          isSuccess: false,
          reportMetricResults: {},
        };
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const getSalesSummaryCardData = useCallback(
    async (body: SalesSummaryDataRequest) =>
      callBsl<SalesSummaryDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['sales-summary', 'sales-cards'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          netSales: 0,
          discounts: 0,
          charges: 0,
          taxes: 0,
          grossSales: 0,
        };
      }),
    [throwBspError, callBsl]
  );

  const getSalesSummaryGridData = useCallback(
    async (body: SalesSummaryDataRequest) =>
      callBsl<SalesSummaryGridResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['sales-summary', 'sales-grid'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getSalesSummaryPaymentsGridData = useCallback(
    async (body: SalesSummaryDataRequest) =>
      callBsl<SalesPaymentsGridResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['sales-summary', 'payments-grid'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getRefundsGridData = useCallback(
    async (body: RefundsGridDataRequest) =>
      callBsl<RefundDataGridResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['refunds', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getRefundsCardsData = useCallback(
    async (body: RefundsCardsDataRequest) =>
      callBsl<RefundCardsDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['refunds'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          refundedCount: 0,
          refundAmount: 0,
          refundCountComparisonPercent: 0,
          refundAmountComparisonPercent: 0,
        };
      }),
    [throwBspError, callBsl]
  );

  const getKPIData2 = useCallback(
    async (body: KPIDataRequest) =>
      callBsl<KPIDataResponse>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['dashboard', 'kpi'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        queryParams: [
          { kind: 'single', key: 'startDate', value: body.startDate },
          { kind: 'single', key: 'endDate', value: body.endDate },
          { kind: 'single', key: 'currentDate', value: body.currentDate },
          { kind: 'multi', key: 'metricsRequested', value: body.metricsRequested },
        ],
      }).catch((err) => {
        throwBspError(err);
        return {
          isSuccess: false,
          reportMetricResults: {},
        } as KPIDataResponse;
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const getTransactionsDetailsExportGridData = useCallback(
    async (body: TransactionDetailsExportDataRequest) =>
      callBsl<TransactionDetailsExportGridResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['transactions-export-view', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          response: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getDashboardGraphData = useCallback(
    async (body: DashboardGraphDataRequest) =>
      callBsl<DashboardGraphDataResponse>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['dashboard', 'graph-data'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        queryParams: [
          { kind: 'single', key: 'startDate', value: body.startDate },
          { kind: 'single', key: 'endDate', value: body.endDate },
        ],
      }).catch((err) => {
        throwBspError(err);
        return {
          xAxis: [],
          netSales: [],
          comparisonNetSales: [],
          forecastedNetSales: [],
        } as DashboardGraphDataResponse;
      }),
    [selectedSite.enterpriseUnitId, throwBspError, callBsl]
  );

  const getProductMixMenuGridData = useCallback(
    async (body: ProductMixMenuGridDataRequest) =>
      callBsl<ProductMixMenuGridDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['productmix', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getProductMixTop5Items = useCallback(
    async (body: ProductMixTop5ItemsRequest) =>
      callBsl<ProductMixTop5ItemsResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['productmix', 'top-items'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          items: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getProductMixTotalSales = useCallback(
    async (body: ProductMixTotalSalesRequest) =>
      callBsl<ProductMixTotalSalesResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['productmix', 'total-sales'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          selectedDateRange: [],
          comparisonDateRange: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getProductMixTop5Sellers = useCallback(
    async (body: ProductMixEmployeeSalesDataRequest) =>
      callBsl<ProductMixTop5SellersResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['productmix', 'top-sellers'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          topSellers: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getProductMixEmployeeSalesGrid = useCallback(
    async (body: ProductMixEmployeeSalesDataRequest) =>
      callBsl<ProductMixEmployeeSalesGridResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['productmix', 'employee-sales-grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getCalendarFilters = useCallback(
    async (): Promise<Calendar.FiscalCalendarFilters | undefined> =>
      callBsl<Calendar.FiscalCalendarFilters>({
        proxy: proxies.calendar,
        verb: Verb.GET,
        pathSegments: ['fiscal-calendar', 'filters'],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const getEmployeeGeneralInfoUrl = useCallback(
    async (posEmployeeId: string): Promise<string | undefined> =>
      callBsl<EmployeeConfigurationsResponse>({
        proxy: proxies.employeeConfiguration,
        verb: Verb.GET,
        pathSegments: ['employee-configurations', 'id-by-pos-emp-id', posEmployeeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      })
        .then((res) =>
          res.employeeId
            ? `${window.location.origin}/labor/employees/overview/${res.employeeId}/generalInformation`
            : undefined
        )
        .catch((err) => {
          throwBspError(err);
          return undefined;
        }),
    [throwBspError, selectedSite.enterpriseUnitId, callBsl]
  );

  const getGlAccounts = useCallback(
    async (body: GlAccountsRequest) =>
      callBsl<GlAccountsResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['gl-accounts'],
        queryParams:
          // If all the arrays are empty it causes a weird authentication error, so avoid that.
          body.isActive ||
          body.categoryIds.length > 0 ||
          body.accountTypes.length > 0 ||
          body.accountDetailTypes.length > 0
            ? SalesUtilities.toGetGlAccountsQueryParams(body)
            : undefined,
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const getGlAccountFilters = useCallback(
    async () =>
      callBsl<GlAccountFiltersResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['gl-accounts', 'filters'],
      }).catch((err) => {
        throwBspError(err);
        return undefined;
      }),
    [throwBspError, callBsl]
  );

  const getGlAccountUpsertInfo = useCallback(
    async () =>
      callBsl<GlAccountUpsertInfoResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['gl-accounts', 'info'],
      }).catch((err) => {
        throwBspError(err);
        return err;
      }),
    [throwBspError, callBsl]
  );

  const getAllGlAccountNames = useCallback(
    async () =>
      callBsl<GlAccountName[]>({
        proxy: proxies.glAccounts,
        verb: Verb.GET,
        pathSegments: ['gl-accounts', 'names'],
      }).catch((err) => {
        throwBspError(err);
        return err;
      }),
    [throwBspError, callBsl]
  );

  const deleteGlAccount = useCallback(
    async (body: GlAccountsDeleteRequest): Promise<boolean> =>
      callBsl<GlAccountsResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.DELETE,
        pathSegments: ['gl-accounts'],
        payload: body,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [throwBspError, callBsl]
  );

  const createGlAccount = useCallback(
    async (body: CreateGlAccountRequest): Promise<CreateGlAccountsResponse> =>
      callBsl<CreateGlAccountsResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.POST,
        pathSegments: ['gl-accounts'],
        payload: body,
      }).catch((err) => err.details?.constraintViolations ?? t('sales.errors.genericMessage')),
    [callBsl, t]
  );

  const updateGlAccount = useCallback(
    async (glAccountId: string, body: UpdateGlAccountRequest): Promise<boolean> =>
      callBsl<void>({
        proxy: proxies.glAccounts,
        verb: Verb.PUT,
        pathSegments: ['gl-accounts', glAccountId],
        payload: body,
      })
        .then(() => true)
        .catch((err) => err.details?.constraintViolations ?? t('sales.errors.genericMessage')),
    [t, callBsl]
  );

  const restoreDeleteGlAccount = useCallback(
    async (body: GlAccountsDeleteRequest): Promise<boolean> =>
      callBsl<GlAccountsResponse>({
        proxy: proxies.glAccounts,
        verb: Verb.PATCH,
        pathSegments: ['gl-accounts'],
        payload: body,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [throwBspError, callBsl]
  );

  const updateIsActiveGlAccounts = useCallback(
    async (body: UpdateIsActiveGlAccountsRequest): Promise<boolean> =>
      callBsl<void>({
        proxy: proxies.glAccounts,
        verb: Verb.PUT,
        pathSegments: ['gl-accounts'],
        payload: body,
      })
        .then(() => true)
        .catch((err) => {
          throwBspError(err);
          return false;
        }),
    [throwBspError, callBsl]
  );

  const uploadGlAccounts = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (file: File): Promise<AxiosResponse<any, any>> => {
      if (!user.oktaToken) {
        throw new Error('No okta token found');
      }

      // Add all required values for making bulk-import endpoint call inside form-data
      const formData = new FormData();
      formData.append('file', file);
      formData.append('bslId', `${user.org?.bslId}`);
      formData.append('proxy', proxies.glAccounts);
      formData.append('verb', Verb.PUT);
      formData.append('pathSegments', 'gl-accounts,bulk-import');
      formData.append('oktaJwt', user.oktaToken);

      // Make proxy url string
      const path = '/bslproxyncridwithformdata';
      const proxyUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOST}${path}`;

      // Make headers for request
      const config = {
        headers: {
          Authorization: `Bearer ${user.oktaToken}`,
        },
      };

      return axios.post(proxyUrl, formData, config);
    },
    [user.oktaToken, user.org?.bslId]
  );

  const getGlAccountById = useCallback(
    async (glAccountId: string) =>
      callBsl<GlAccount>({
        verb: Verb.GET,
        proxy: proxies.glAccounts,
        pathSegments: ['gl-accounts', 'accountId', glAccountId],
      }).catch((err) => {
        setSnackbarState({
          open: true,
          message: t('sales.errors.genericMessage'),
          color: 'error',
        });
        return undefined;
      }),
    [callBsl, setSnackbarState, t]
  );

  const getSalesDefinitionsSettings = useCallback(
    async () =>
      callBsl<SalesDefinitionsSettings>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['sales-report-config'],
      }).catch((err) => {
        throwBspError(err);
        return err;
      }),
    [throwBspError, callBsl]
  );

  const updateSalesDefinitionsSettings = useCallback(
    async (body: UpdateSalesDefinitionsSettings): Promise<Id> =>
      callBsl<void>({
        proxy: proxies.sales,
        verb: Verb.PUT,
        pathSegments: ['sales-report-config'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return err;
      }),
    [throwBspError, callBsl]
  );

  const getProfitLossGridData = useCallback(
    async (body: ProfitLossGridDataRequest) =>
      callBsl<ProfitLossPageResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['profit-loss', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {} as ProfitLossPageResponse;
      }),
    [throwBspError, callBsl]
  );

  const getTotalTaxesData = useCallback(
    async (body: TaxesRequestDto) =>
      callBsl<TotalTaxesResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['taxes', 'total-taxes'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return err;
      }),
    [throwBspError, callBsl]
  );

  const getTaxesGridData = useCallback(
    async (body: TaxesRequestDto) =>
      callBsl<TaxesGridResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['taxes', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getTaxesByTypeData = useCallback(
    async (body: TaxesRequestDto) =>
      callBsl<TaxesByTypeResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['taxes', 'taxes-by-type'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getDiscountReportKPICardsData = useCallback(
    async (body: DiscountReportKPICardsRequest) =>
      callBsl<DiscountReportKPICardsResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['discounts', 'kpi-cards'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          countOfDiscounts: 0,
          discountCountPercentChange: 0,
          totalAmountOfDiscounts: 0,
          discountAmountPercentChange: 0,
        };
      }),
    [throwBspError, callBsl]
  );

  const getDiscountReportTop5DiscountsData = useCallback(
    async (body: DiscountReportCommonRequest) =>
      callBsl<DiscountReportTop5DiscountsResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['discounts', 'top-discounts'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          discountItems: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getDiscountReportGridData = useCallback(
    async (body: DiscountReportCommonRequest) =>
      callBsl<DiscountReportGridDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['discounts', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getSalesByRevenueCenterData = useCallback(
    async (body: RevenueCenterCommonRequest) =>
      callBsl<SalesByRevenueCenterResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['revenue-centers', 'sales-by-revenue-center'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getPerPersonAverageByRevenueCenterData = useCallback(
    async (body: RevenueCenterCommonRequest) =>
      callBsl<PerPersonAverageByRevenueCenterResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['revenue-centers', 'per-person-average'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getRevenueCenterGridData = useCallback(
    async (body: RevenueCenterCommonRequest) =>
      callBsl<RevenueCenterGridResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['revenue-centers', 'grid'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getVoidsReportGridData = useCallback(
    async (body: VoidsReportCommonRequest) =>
      callBsl<VoidsReportGridDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['voids', 'grid-data'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getVoidsReportTop5ReasonsData = useCallback(
    async (body: VoidsReportCommonRequest) =>
      callBsl<VoidsReportTop5ReasonsDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['voids', 'top-void-reasons'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          voidReasons: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getVoidsReportKPICardsData = useCallback(
    async (body: VoidsReportKPICardsDataRequest) =>
      callBsl<VoidsReportKPICardsDataResponse>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['voids', 'kpi-cards'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          totalVoidsCount: 0,
          voidsCountPercentChange: 0,
          totalVoidsAmount: 0,
          voidsAmountPercentChange: 0,
        };
      }),
    [throwBspError, callBsl]
  );

  const getTotalPaymentsData = useCallback(
    async (body: PaymentsComparisonRequestDto) =>
      callBsl<TotalPaymentsResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['payments', 'total-payments'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          paymentsAmount: 0,
          comparisonPaymentsAmount: 0,
          amountComparisonPercentage: 0,
        };
      }),
    [throwBspError, callBsl]
  );

  const getPaymentsByTypeData = useCallback(
    async (body: PaymentsRequestDto) =>
      callBsl<PaymentsByTypeResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['payments', 'payments-by-type'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  const getTotalSalesByShift = useCallback(
    async (date: string) =>
      callBsl<TotalSalesByShiftResponseDto[]>({
        proxy: proxies.sales,
        verb: Verb.GET,
        pathSegments: ['punch-summary', 'total-sales', selectedSite.enterpriseUnitId, date],
      }).catch((err) => {
        throwBspError(err);
        return [];
      }),
    [throwBspError, callBsl, selectedSite.enterpriseUnitId]
  );

  const getPaymentsGridData = useCallback(
    async (body: PaymentsRequestDto) =>
      callBsl<PaymentsGridResponseDto>({
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['payments', 'grid'],
        payload: body,
      }).catch((err) => {
        throwBspError(err);
        return {
          results: [],
        };
      }),
    [throwBspError, callBsl]
  );

  return {
    getHouseAccount,
    getHouseAccountsActivities,
    getHouseAccountsSummary,
    createHouseAccount,
    putHouseAccount,
    createStatement,
    createStatementPDF,
    deleteStatement,
    patchStatement,
    getStatement,
    patchHouseAccount,
    deleteHouseAccount,
    restoreHouseAccount,
    createTransaction,
    getTransaction,
    getSaleTransaction,
    getGuestTransaction,
    getAllGuestChecks,
    createPdf,
    getKPIData,
    getDashboardGraphData,
    getSalesSummaryCardData,
    getKPIData2,
    getCalendarFilters,
    getEmployeeGeneralInfoUrl,
    getPOSEventFilters,
    getPOSEvents,
    getProductMixMenuGridData,
    getGlAccounts,
    getGlAccountFilters,
    deleteGlAccount,
    createGlAccount,
    getGlAccountUpsertInfo,
    getAllGlAccountNames,
    updateGlAccount,
    uploadGlAccounts,
    restoreDeleteGlAccount,
    updateIsActiveGlAccounts,
    getGlAccountById,
    getSalesDefinitionsSettings,
    updateSalesDefinitionsSettings,
    getProductMixTop5Items,
    getProductMixTotalSales,
    getProductMixTop5Sellers,
    getProductMixEmployeeSalesGrid,
    getFilterValues,
    getDaypartFiltersBslRequest,
    getSalesSummaryGridData,
    getSalesSummaryPaymentsGridData,
    getProfitLossGridData,
    getRefundsGridData,
    getRefundsCardsData,
    getTotalTaxesData,
    getTaxesGridData,
    getTaxesByTypeData,
    getDiscountReportKPICardsData,
    getDiscountReportTop5DiscountsData,
    getDiscountReportGridData,
    getSalesByRevenueCenterData,
    getPerPersonAverageByRevenueCenterData,
    getRevenueCenterGridData,
    getVoidsReportGridData,
    getVoidsReportKPICardsData,
    getVoidsReportTop5ReasonsData,
    getTransactionsDetailsExportGridData,
    getTotalPaymentsData,
    getPaymentsByTypeData,
    getPaymentsGridData,
    getTotalSalesByShift,
  };
};

export default useSalesRequests;
