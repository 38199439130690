import OpenInNew from '@mui/icons-material/OpenInNew';
import { useTheme, Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../constants/routes';
import { useCompanyLinks } from '../../contexts/companyLinksContext';
import { isExternalLink, prependHttp, truncateString } from '../../utils';
import LinkComponent from './link-component';
import useNavItems, { NavItem } from './routes';
import styles from './style.module.css';
import { useUsers } from '../../contexts/userContext';
import { APPBAR_HEIGHT } from '../AppBar/AppBar';
import SidebarContent from './ncr-design-system-components/CustomSidebar/CustomSidebarContent';
import SidebarFooter from './ncr-design-system-components/CustomSidebar/CustomSidebarFooter';
import SidebarHeader from './ncr-design-system-components/CustomSidebar/CustomSidebarHeader';
import { NavigationBackButton } from './ncr-design-system-components/Navigation/NavigationBackButton';
import { NavigationItem } from './ncr-design-system-components/Navigation/NavigationItem';
import NavigationSwitch from './ncr-design-system-components/Navigation/NavigationSwitch';
import NavigationView from './ncr-design-system-components/Navigation/NavigationView';
import { Skeleton } from './ncr-design-system-components/Skeleton/Skeleton';
import { NavigationMenu } from './ncr-design-system-components/Navigation/NavigationMenu';

function Item(props: { selectedPath: string | undefined; link: NavItem; setViewPath: (viewPath: string) => void }) {
  const { link, selectedPath, setViewPath, ...rest } = props;
  if (link.forceNewFrame) {
    return (
      <NavigationItem
        role='listitem'
        icon={link.icon}
        title={link.title}
        path={link.testid}
        data-testid={link.testid}
        variant='drilldown'
        onClick={() => {
          setViewPath?.(link.href || '/');
        }}
        {...rest}
      />
    );
  }
  if (link.children) {
    return (
      <NavigationItem
        selected={link.children?.some((item) => item.href === selectedPath)}
        icon={link.icon}
        title={link.title}
        path={`${link.href}*`}
        data-testid={link.testid}
        role='listitem'
      >
        <ul
          style={{
            padding: 0,
          }}
        >
          {link.children?.map((sublink, j) => (
            <Item selectedPath={selectedPath} key={sublink.testid} link={sublink} setViewPath={setViewPath} />
          ))}
        </ul>
      </NavigationItem>
    );
  }
  const hasInternalPath = link.href && !isExternalLink(link.href);
  return (
    <LinkComponent href={link.href} className={styles.link} role='listitem'>
      <NavigationItem
        icon={link.icon}
        title={link.title}
        path={hasInternalPath ? link.href : link.testid}
        data-testid={link.testid}
        role='none'
        tabIndex={-1}
      />
    </LinkComponent>
  );
}

interface AppSidebarProps {
  ncridUrl: string;
}

function AppSidebar({ ncridUrl }: AppSidebarProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const user = useUsers();
  const [viewPath, setViewPath] = useState(pathname);
  useEffect(() => {
    setViewPath(pathname);
  }, [pathname]);

  const { sidebarLinks } = useCompanyLinks();
  const companyLinks = useMemo(
    () =>
      sidebarLinks?.map((link, index) => {
        const cleanedUrl = prependHttp(link.url.replaceAll(' ', ''), { https: false });
        const testid = `${link.displayName.replaceAll(' ', '')}-link-${index}`;
        return {
          title: truncateString(link.displayName, 21),
          href: cleanedUrl,
          testid,
          icon: <OpenInNew />,
          key: `${cleanedUrl}-${link.id}`,
        };
      }) || [],
    [sidebarLinks]
  );
  const links = useNavItems(companyLinks, ncridUrl);
  const views = useMemo(() => links.filter((x) => x.forceNewFrame), [links]);

  const renderSkeletons = () => {
    const rows = [];
    const widths = ['100%', '50%', '66%', '100%', '33%'];
    for (let i = 0; i < widths.length; i += 1) {
      rows.push(<Skeleton variant='rounded' height={36} sx={{ marginBottom: '15px' }} width={widths[i]} key={i} />);
    }
    return rows;
  };
  return (
    <>
      <SidebarHeader
        width='100%'
        height={APPBAR_HEIGHT}
        py={2}
        borderBottom={isMobile ? `1px solid ${theme.palette.divider}` : 0}
      >
        <Link to={routes.HOME_PAGE} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
          <Typography variant='h6' sx={{ width: '100%', fontWeight: 500 }}>
            {t('navItems.alohaSmartManager')}
          </Typography>
        </Link>
      </SidebarHeader>
      <SidebarContent>
        {!user.bslAuth || user.isOrgSwitching ? (
          <Box sx={{ margin: '15px' }}>{renderSkeletons()}</Box>
        ) : (
          <NavigationSwitch view={viewPath} selection={pathname} data-testid='sidebar-navigation-switch'>
            {views.map((view) => (
              <NavigationView path={`${view.href}*`} key={view.href}>
                <NavigationBackButton
                  title={view.title || ''}
                  onClick={() => {
                    setViewPath('/');
                  }}
                />
                <NavigationMenu>
                  {view.children?.map((link, i) => (
                    <Item selectedPath={pathname} key={link.testid} link={link} setViewPath={setViewPath} />
                  ))}
                </NavigationMenu>
              </NavigationView>
            ))}
            <NavigationView path='*'>
              <NavigationMenu>
                {links.map((link, i) => (
                  <Item selectedPath={pathname} key={link.testid} link={link} setViewPath={setViewPath} />
                ))}
              </NavigationMenu>
            </NavigationView>
          </NavigationSwitch>
        )}
      </SidebarContent>
      <SidebarFooter>
        <img src='/ncr-logo.png' alt='aloha-by-ncr-voyix' />
      </SidebarFooter>
    </>
  );
}

export default AppSidebar;
