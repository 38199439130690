import { ReactNode, useState } from 'react';
import { SwitcherContext, SwitcherOptions } from 'src/contexts/SwitcherContext';
import { useDeepEffect } from 'src/hooks/useDeepEffect';

export const getDefaultConfig = (): SwitcherOptions => ({
    menuTransformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    menuAnchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
});

export const SwitcherProvider = ({
    config,
    children,
}: {
    config: SwitcherOptions;
    children: ReactNode;
}) => {
    const [switcherConfig, setSwitcherConfig] = useState<SwitcherOptions>(getDefaultConfig());

    useDeepEffect(() => {
        setSwitcherConfig(config);
    }, [config]);

    return <SwitcherContext.Provider value={switcherConfig}>{children}</SwitcherContext.Provider>;
};
