import axios, { InternalAxiosRequestConfig } from 'axios';
import { Global } from '../constants/global';
import { AppConstants } from 'src/constants/app';

const authClient = axios.create();

authClient.interceptors.request.use(
    (r: InternalAxiosRequestConfig) => {
        if (Global.AccessToken) r.headers.Authorization = `AccessToken ${Global.AccessToken}`;
        if (Global.NepOrganization && r.headers[AppConstants.OrgHeader] === undefined)
            r.headers[AppConstants.OrgHeader] = Global.NepOrganization;
        r.withCredentials = true;
        return r;
    },
    null,
    { synchronous: true },
);
export default authClient;
