import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

const defaultSxProps = { padding: '16px' };

export const SwipeableDrawerFooter = ({
    sx = {},
    children,
}: {
    sx?: SxProps;
    children: ReactNode;
}) => {
    return (
        <Box
            height={'auto'}
            width={'100%'}
            sx={{
                ...defaultSxProps,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
