import {
    ListItem,
    ListItemButton,
    ListItemButtonProps,
    ListItemProps,
    SxProps,
    Theme,
} from '@mui/material';
import { ReactNode } from 'react';
import { ThemeValues } from 'src/constants/theme-values';

const defaultSxProps = {
    px: 0,
    py: 0,
    '.MuiListItemSecondaryAction-root': {
        right: '8px',
    },
};

export const DialogListItemActionButton = ({
    children,
    sx = {},
    buttonProps = {},
    selected,
    onListItemButtonClick,
    ...listItemProps
}: {
    children: ReactNode;
    sx?: SxProps<Theme>;
    buttonProps?: ListItemButtonProps & { href?: string; target?: string };
    selected?: boolean;
    onListItemButtonClick: () => void;
} & ListItemProps) => {
    return (
        <ListItem
            sx={{
                ...defaultSxProps,
                ...sx,
            }}
            {...listItemProps}
        >
            <ListItemButton
                selected={selected}
                sx={{
                    margin: '4px 0',
                    padding: '8px 0px 8px 12px',
                    minHeight: '48px',
                    borderRadius: ThemeValues.BorderRadius,
                }}
                {...buttonProps}
                onClick={onListItemButtonClick}
            >
                {children}
            </ListItemButton>
        </ListItem>
    );
};
