import { useState } from 'react';
import { SwitcherDialog } from 'src/components/common/Switcher/SwitcherDialog';
import { useApplicationsContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationAllAppsList } from './ApplicationAllAppsList';

export const ApplicationSwitcherDialog = () => {
    const { t } = useTranslationNs();
    const [searchValue, setSearchValue] = useState('');

    const { isAppDialogOpen, setIsAppDialogOpen } = useApplicationsContext();

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
    };

    const handleAppClick = () => {
        setIsAppDialogOpen(false);
        setSearchValue('');
    };

    const handleDialogClose = () => {
        setIsAppDialogOpen(false);
    };

    return (
        <SwitcherDialog
            isOpen={isAppDialogOpen}
            dialogTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            dialogCloseTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.CLOSE')}
            onClose={handleDialogClose}
            onSearch={handleSearchValueChange}
        >
            <ApplicationAllAppsList onAppClick={handleAppClick} searchValue={searchValue} />
        </SwitcherDialog>
    );
};
