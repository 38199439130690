import { useEffect, useState } from 'react';
import { isDeepEqual } from 'src/utils/deep-equal.utils';

export const useDeepEffect = (cb: () => VoidFunction | void, dependencies: any[]) => {
    const [oldDependencies, setOldDependencies] = useState<any[]>([]);

    useEffect(() => {
        if (!isDeepEqual(oldDependencies, dependencies)) {
            setOldDependencies([...dependencies]);
            return cb();
        }
    }, dependencies);
};
