import { Cancel, Search } from '@mui/icons-material';
import {
    Box,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

export const DialogSearchBar = ({ onChange }: { onChange: (value: string) => void }) => {
    const theme = useTheme<Theme>();
    const { t } = useTranslationNs();
    const [inputValue, setInputValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        onChange(value);
    };

    const handleClear = () => {
        inputRef.current!.value = '';
        setInputValue('');
        onChange('');
    };

    const activeStyle: SxProps = Boolean(inputValue)
        ? {}
        : {
              opacity: 0,
              pointerEvents: 'none',
          };

    return (
        <TextField
            ref={inputRef}
            InputLabelProps={{
                shrink: false,
                focused: false,
            }}
            value={inputValue}
            InputProps={{
                endAdornment: (
                    <Stack
                        sx={{ position: 'relative' }}
                        direction='row'
                        alignItems='center'
                        columnGap={1}
                    >
                        <IconButton
                            onClick={handleClear}
                            sx={{
                                zIndex: 2,
                                padding: '8px',
                                ...activeStyle,
                                svg: {
                                    fontSize: '20px',
                                },
                            }}
                            aria-label='cancel'
                            size='small'
                        >
                            <Tooltip title={t('SEARCH.CLEAR')}>
                                <Cancel />
                            </Tooltip>
                        </IconButton>
                        <Divider orientation='vertical' sx={{ height: '24px' }} />
                        <InputAdornment
                            sx={{
                                '.MuiSvgIcon-root': {
                                    height: '24px',
                                    width: '24px',
                                },
                            }}
                            position='end'
                        >
                            <Search />
                        </InputAdornment>
                    </Stack>
                ),
            }}
            onChange={handleOnChange}
            autoComplete='off'
            size='small'
            label={
                <Box component='span' sx={{ display: !!inputValue ? 'none' : 'block' }}>
                    {t('SEARCH.INPUT_LABEL')}
                </Box>
            }
            autoFocus
            sx={{
                width: '100%',
                '.MuiInputBase-root': {
                    borderRadius: '8px',
                    overflow: 'hidden',
                    padding: '4px 12px',
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.grey[100] : 'transparent',
                },
                '.MuiFormLabel-root': {
                    transform: 'translate(12px, 13px) scale(1)',
                },
                '.MuiInputBase-input': {
                    paddingLeft: 0,
                },
                '.MuiInputAdornment-root': {
                    padding: '8px',
                    margin: 0,
                },
            }}
        />
    );
};
