// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import ActionsDialog from '../ActionsDialog/ActionsDialog';

interface UnsavedFormDataPromptProps {
  open?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  when?: boolean | undefined;
  navigate?: (path: string) => void;
  shouldBlockNavigation?: (location: Location) => boolean;
  textTranslationKey?: string;
}

function UnsavedFormDataPrompt({
  open,
  onCancel,
  onConfirm,
  when,
  navigate,
  shouldBlockNavigation,
  textTranslationKey,
}: UnsavedFormDataPromptProps) {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    if (onCancel) onCancel();
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    if (onConfirm) onConfirm();
  };

  useEffect(() => {
    if (open) setModalVisible(true);
  }, [open]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation && navigate) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useEffect(() => {
    const onBeforeUnload = (e: { preventDefault: () => void; returnValue: string }) => {
      if (when) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [when]);

  useEffect(() => {
    if (when === false && modalVisible) {
      // 'when' condition no longer applies, close modal
      setModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  return (
    <>
      {when && handleBlockedNavigation && <Prompt when={when} message={handleBlockedNavigation} />}
      <ActionsDialog
        open={modalVisible}
        dataTestId='unsaved-data-dialog'
        dialogTitle={t(`${textTranslationKey}.title`)}
        onClose={closeModal}
        dialogContentText={t(`${textTranslationKey}.content`)}
        closeButtonText={t(`${textTranslationKey}.cancelButton`)}
        closeButtonVariant='text'
        actionButtonText={t(`${textTranslationKey}.okButton`)}
        actionButtonColor='error'
        onActionClick={handleConfirmNavigationClick}
      />
    </>
  );
}

UnsavedFormDataPrompt.defaultProps = {
  open: false,
  when: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  navigate: undefined,
  shouldBlockNavigation: undefined,
  textTranslationKey: 'unsavedDataDialog',
};

export default UnsavedFormDataPrompt;
