import { AxiosError } from 'axios';
import { ApiError, NcrPageResponse } from '../types';

export const paginateApiCalls = async <T>(
    req: (page: number) => Promise<NcrPageResponse<T>>,
): Promise<T[]> => {
    const firstResponse = await req(0);

    if (firstResponse.lastPage) {
        return firstResponse.pageContent;
    }

    const dataToReturn: T[] = [...firstResponse.pageContent];

    const requests: Promise<NcrPageResponse<T>>[] = [];
    for (let i = 1; i < firstResponse.totalPages; i++) {
        requests.push(req(i));
    }

    const allPages = await Promise.all(requests);
    allPages.forEach((npr: NcrPageResponse<T>) => dataToReturn.push(...npr.pageContent));

    return dataToReturn;
};

export const DefaultError: ApiError = {
    status: 500,
    message: 'Unknown Error',
};

export const DefaultErrorHandler = (e: any) => {
    if (e instanceof AxiosError) {
        const coreErrorMessage = e?.response?.data?.message;

        throw {
            status: e?.response?.status,
            message: coreErrorMessage || e?.message,
        };
    } else {
        throw {
            ...DefaultError,
        };
    }
};
