import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export const SidebarContext = createContext<{
  isCollapsable: boolean; // whether the sidebar is able to collapse for the current breakpoint
  isCollapsed: boolean; // whether the sidebar should collapse when in tablet or mobile mode
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  isAttached: boolean; // whether the child component is within a sidebar provider
}>({
  isCollapsable: true,
  isCollapsed: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCollapsed: () => {},
  isAttached: false,
});

export const useSidebar = () => useContext(SidebarContext);
