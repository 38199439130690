import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Ty from '@mui/material/Typography';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useDeleteGlAccountMutation, useRestoreDeleteGlAccountMutation } from '../requests/mutations';
import { GlAccount } from '../requests/requests';
import SalesUtilities from '../utilities';
import ActionsDialog from '../../components/ActionsDialog/ActionsDialog';
import GeneralLedgerAccountsDialogContent from './GeneralLedgerAccountsDialogContent';

export interface GeneralLedgerAccountsDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  glAccounts: GlAccount[];
  fromActionsMenu: boolean;
  setFromActionsMenu: Dispatch<SetStateAction<boolean>>;
  setBulkActionAccountTree: Dispatch<SetStateAction<GlAccount[] | undefined>>;
}

function GeneralLedgerAccountsDeleteDialog(props: GeneralLedgerAccountsDeleteDialogProps) {
  const { open, onClose, onSuccess, glAccounts, fromActionsMenu, setFromActionsMenu, setBulkActionAccountTree } = props;
  const { t } = useTranslation();
  const { setSnackbarState } = useSnackbar();
  const { mutateAsync: deleteGlAccount } = useDeleteGlAccountMutation();
  const { mutateAsync: restoreDeleteGlAccount } = useRestoreDeleteGlAccountMutation();

  const handleRestoreDeletedAccount = async () => {
    const accountIds: string[] = [];
    glAccounts.forEach((account) => {
      const subAccountIds = SalesUtilities.getAllGlAccountIds(account);
      accountIds.push(...subAccountIds);
    });
    const result = await restoreDeleteGlAccount({
      accountIds,
    });
    if (result) {
      setSnackbarState({
        open: false,
      });
      onSuccess();
    } else {
      onClose();
    }
  };

  const afterSuccessfulDelete = async () => {
    onSuccess();
    setSnackbarState({
      open: true,
      message:
        glAccounts.length === 1 ? (
          <Trans
            i18nKey={
              !glAccounts[0].subAccounts.length
                ? 'admin.glAccountConfiguration.actionContent.successMessageNoChildren'
                : 'admin.glAccountConfiguration.actionContent.successMessageWithChildren'
            }
            values={{
              glAccountNumber: glAccounts[0].accountNumber,
              glAccountName: glAccounts[0].accountName,
              action: t('admin.glAccountConfiguration.actionContent.deleted'),
            }}
            components={{ primary: <Ty sx={{ fontWeight: '500' }} display='inline' /> }}
          />
        ) : (
          t('admin.glAccountConfiguration.actionContent.bulkSuccess', {
            action: t('admin.glAccountConfiguration.actionContent.deleted'),
          })
        ),
      color: 'success',
      actionText: t('buttonText.undo'),
      actionCallback: () => {
        handleRestoreDeletedAccount();
      },
    });
    if (fromActionsMenu) {
      setBulkActionAccountTree(undefined);
      setFromActionsMenu(false);
    }
  };

  const handleDelete = async () => {
    const accountIds: string[] = [];
    glAccounts.forEach((account) => {
      const subAccountIds = SalesUtilities.getAllGlAccountIds(account);
      accountIds.push(...subAccountIds);
    });
    const result = await deleteGlAccount({
      accountIds,
    });
    if (result) {
      afterSuccessfulDelete();
    } else {
      onClose();
    }
  };

  const additionalDialogTitleContent = () => {
    let content: React.ReactNode = null;
    if (glAccounts.length === 1) {
      content = (
        <Ty sx={{ marginTop: '24px', marginBottom: '8px' }} data-testid='gl-accounts-delete-modal-title-content'>
          {!glAccounts[0].subAccounts.length ? (
            t('admin.glAccountConfiguration.actionContent.additionalDialogTitleSingleAccount', {
              action: t('admin.glAccountConfiguration.actionContent.delete'),
            })
          ) : (
            <Trans
              i18nKey='admin.glAccountConfiguration.actionContent.additionalDialogTitleSingleAndSub'
              values={{
                numberOfGlAccounts: SalesUtilities.getNumberOfAccountsInTree(glAccounts[0]),
                action: t('admin.glAccountConfiguration.actionContent.delete'),
                actionStart: t('admin.glAccountConfiguration.actionContent.deleting'),
              }}
            />
          )}
        </Ty>
      );
    } else if (glAccounts.length > 1) {
      content = (
        <Ty sx={{ marginTop: '24px', marginBottom: '8px' }} data-testid='gl-accounts-delete-modal-title-content'>
          <Trans
            i18nKey={
              SalesUtilities.checkIfSubAccountsExist(glAccounts)
                ? 'admin.glAccountConfiguration.actionContent.additionalDialogTitleMultipleAndSub'
                : 'admin.glAccountConfiguration.actionContent.additionalDialogTitleMultipleAccounts'
            }
            values={{
              action: t('admin.glAccountConfiguration.actionContent.delete'),
              actionStart: t('admin.glAccountConfiguration.actionContent.deleting'),
              numberOfGlAccounts: SalesUtilities.getNumberOfGlAccounts(glAccounts),
            }}
          />
        </Ty>
      );
    }
    return content;
  };

  const dialogTitle = () => {
    let title = '';
    if (glAccounts.length === 1) {
      title = t('admin.glAccountConfiguration.actionContent.actionTitleWithGlAccount', {
        glAccountNumber: glAccounts[0].accountNumber,
        glAccountName: glAccounts[0].accountName,
        action: t('admin.glAccountConfiguration.actionContent.deleteTitle'),
      });
    } else if (glAccounts.length > 1) {
      title = t('admin.glAccountConfiguration.actionContent.actionTitleBulkActions', {
        action: t('admin.glAccountConfiguration.actionContent.deleteTitle'),
      });
    }
    return title;
  };

  return (
    <ActionsDialog
      open={open}
      onClose={onClose}
      dialogTitle={dialogTitle()}
      additionalDialogTitleContent={additionalDialogTitleContent()}
      additionalDialogContent={<GeneralLedgerAccountsDialogContent glAccounts={glAccounts} />}
      closeButtonText={t('buttonText.cancel')}
      closeButtonVariant='text'
      actionButtonText={t('buttonText.delete')}
      actionButtonColor='error'
      actionButtonVariant='contained'
      onActionClick={handleDelete}
      dataTestId='gl-accounts-delete-modal'
    />
  );
}

export default GeneralLedgerAccountsDeleteDialog;
