import { TFunction } from 'i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useOrganizationContext } from 'src/contexts';
import { ApplicationsContext, IApplicationsContext } from 'src/contexts/ApplicationsContext';
import { useGetApplicationsForUser } from 'src/fetchers/applications.fetchers';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationModel } from 'src/types/application.type';

const findMatchingApp = (
    apps: ApplicationModel[],
    t: TFunction<[string, string]>,
    defaultApp?: string,
): ApplicationModel | undefined => {
    let app: ApplicationModel | undefined;

    if (defaultApp) {
        app = apps.find((am: ApplicationModel) => am.name === defaultApp);
    }

    if (!app) {
        const currentHost = window.location.hostname;
        app = apps.find((am: ApplicationModel) => am.baseUrl.includes(currentHost));
    }

    if (!app) console.warn(t('WARNINGS.NO_DEFAULT_APPLICATION'));
    return app;
};

export const ApplicationsContextProvider = ({
    defaultApp,
    children,
}: {
    defaultApp?: string;
    children: ReactNode;
}) => {
    const { t } = useTranslationNs();
    const { organization } = useOrganizationContext();
    const [isAppDialogOpen, setIsAppDialogOpen] = useState(false);
    const [application, setApplication] = useState<ApplicationModel | undefined>();

    const { userApplications, isLoading, error } = useGetApplicationsForUser(organization);

    useEffect(
        function setActiveApplication() {
            if (userApplications.length > 0) {
                const matchingApp = findMatchingApp(userApplications, t, defaultApp);
                setApplication(matchingApp ?? userApplications[0]);
            } else {
                setApplication(undefined);
            }
        },
        [userApplications, setApplication],
    );

    useEffect(
        function detectNoApplications() {
            const orgName: string | undefined = organization?.organizationName;

            if (!isLoading && userApplications.length === 0 && !!organization) {
                console.warn(t('WARNINGS.NO_APPLICATIONS', { org: orgName }));
            }
        },
        [userApplications, organization, isLoading, t],
    );

    const contextValue: IApplicationsContext = useMemo(
        () => ({
            isAppDialogOpen,
            setIsAppDialogOpen,
            application,
            userApplications,
            isLoading,
            error,
        }),
        [application, userApplications, isLoading, error, isAppDialogOpen, setIsAppDialogOpen],
    );

    return (
        <ApplicationsContext.Provider value={contextValue}>{children}</ApplicationsContext.Provider>
    );
};
