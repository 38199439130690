import dayjs from 'dayjs';
import { KeyDownCallbacks } from '../SchedulingNew/types';

// Helper function for returning good format of time only so that SR can read it nicely (ex.'8:20 PM' instead of '08:20p')
const getReadableTimeFormatFromShiftEventLabel = (str: string): string => {
  const trimmedStr = str.trim();
  // Transforming string ex. '08:15p' to '08:15 PM'
  const readableFormat = `${trimmedStr.slice(0, trimmedStr.length - 1)} ${trimmedStr
    .slice(trimmedStr.length - 1)
    .toUpperCase()}M`;

  return readableFormat;
};

const getEmployeeCellInTheSameRow = (shiftCell: HTMLElement): Element | undefined => {
  const employeeCell = document?.querySelector(
    `.calendar-custom-employee-name[data-resource-id="${shiftCell.dataset.resourceId}"]`
  ) as HTMLElement;

  return employeeCell;
};

const getAllShiftCellsFromTheSameRow = (element: HTMLElement): NodeListOf<Element> => {
  const allCells = document.querySelectorAll(
    `.calendar-custom-add-shift[data-resource-id='${element.dataset.resourceId}']`
  );

  return allCells;
};

const focusLastShiftCellInTheSameRow = (element: HTMLElement): void => {
  const allShiftCells = getAllShiftCellsFromTheSameRow(element);

  if (allShiftCells.length > 0) {
    (allShiftCells[allShiftCells.length - 1] as HTMLElement).focus();
  }
};

const getFirstShiftEventInSameAddShiftCell = (addShiftDiv: HTMLElement): HTMLElement | null => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${addShiftDiv?.dataset.resourceId}"]`
  );
  const firstShiftEventForDesiredDate = Array.from(shiftEventsForEmployee).find((shiftEvent) => {
    if (addShiftDiv.dataset.shiftDate)
      return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(addShiftDiv.dataset.shiftDate.split('T')[0]);
    return false;
  });

  return firstShiftEventForDesiredDate as HTMLElement;
};

const focusFirstShiftCellInTheSameRow = (element: HTMLElement): void => {
  const allShiftCells = getAllShiftCellsFromTheSameRow(element);
  if (allShiftCells.length > 0) {
    const shiftEventToBeFocused = getFirstShiftEventInSameAddShiftCell(allShiftCells[0] as HTMLElement);
    (shiftEventToBeFocused ?? (allShiftCells[0] as HTMLElement)).focus();
  }
};

const getAdjacentEmployeeCell = (employeeCell: HTMLElement, direction: 'up' | 'down'): HTMLElement | undefined => {
  const allEmployees = document.querySelectorAll('.calendar-custom-employee-name');
  if (allEmployees.length) {
    const indexOfCurrent = Array.from(allEmployees).findIndex(
      (emp) => (emp as HTMLElement).dataset.resourceId === employeeCell.dataset.resourceId
    );
    if (direction === 'up' && indexOfCurrent !== 0) {
      return allEmployees[indexOfCurrent - 1] as HTMLElement;
    }
    if (direction === 'down' && indexOfCurrent !== allEmployees.length - 1) {
      return allEmployees[indexOfCurrent + 1] as HTMLElement;
    }
  }
  return undefined;
};

const getAddShiftFromShiftEvent = (shiftEvent: HTMLElement): HTMLElement | undefined => {
  const addShiftDivsForEmployee = document.querySelectorAll(
    `.calendar-custom-add-shift[data-resource-id="${shiftEvent.dataset.resourceId}"]`
  );

  const addShiftForDesiredDate = Array.from(addShiftDivsForEmployee as NodeListOf<HTMLElement>).find((addShiftDiv) => {
    if (shiftEvent.dataset.shiftDate)
      return addShiftDiv.dataset.shiftDate?.includes(shiftEvent.dataset.shiftDate.split('T')[0]);
    return false;
  });

  return addShiftForDesiredDate as HTMLElement;
};

const getLastShiftEventInSameAddShiftCell = (addShiftDiv: HTMLElement): HTMLElement | null => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${addShiftDiv?.dataset.resourceId}"]`
  );
  const lastShiftEventForDesiredDate = Array.from(shiftEventsForEmployee)
    .reverse()
    .find((shiftEvent) => {
      if (addShiftDiv?.dataset.shiftDate)
        return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(addShiftDiv.dataset.shiftDate.split('T')[0]);
      return false;
    });

  return lastShiftEventForDesiredDate as HTMLElement;
};

const getAdjacentAddShift = (
  element: HTMLElement,
  direction: 'up' | 'down' | 'left' | 'right',
  isFirstShiftEvent = false
): HTMLElement | undefined => {
  let queryRow = `[data-row-index="${element.dataset.rowIndex}"]`;
  if (direction === 'down' || direction === 'up') {
    queryRow = `[data-row-index="${Number(element.dataset.rowIndex) + (direction === 'down' ? 1 : -1)}"]`;
  }

  let queryCol = `.calendar-custom-add-shift-${element.dataset.index}`;
  if (direction === 'left' || direction === 'right') {
    queryCol = `.calendar-custom-add-shift-${Number(element.dataset.index) + (direction === 'right' ? 1 : -1)}`;
  }

  const addShiftToBeFocused = document?.querySelector(
    `.fc-scrollgrid .calendar-custom-add-shift${queryCol}${queryRow}`
  ) as HTMLElement;

  if (direction === 'up' && !isFirstShiftEvent) {
    const shiftEventToBeFocused = getLastShiftEventInSameAddShiftCell(element);
    return shiftEventToBeFocused ?? addShiftToBeFocused;
  }

  const shiftEventToBeFocused = getFirstShiftEventInSameAddShiftCell(addShiftToBeFocused);
  return shiftEventToBeFocused ?? addShiftToBeFocused;
};

const getAdjacentShift = (element: HTMLElement, direction: 'before' | 'after'): HTMLElement | undefined => {
  const shiftEventsForEmployee = document.querySelectorAll(
    `.shift-event[data-resource-id="${element.dataset.resourceId}"]`
  );
  const shiftsForSameDayOnly = Array.from(shiftEventsForEmployee as NodeListOf<HTMLElement>).filter((shiftEvent) => {
    if (element.dataset.shiftDate && shiftEvent.dataset.shiftDate)
      return shiftEvent.dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
    return false;
  });

  let desiredShiftEvent: HTMLElement | undefined;
  if (direction === 'after') {
    desiredShiftEvent = shiftsForSameDayOnly.find((shiftEvent) => {
      if (element.dataset.shiftDate && shiftEvent.dataset.shiftDate)
        // return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
        return dayjs(shiftEvent.dataset.shiftDate).diff(dayjs(element.dataset.shiftDate)) > 0;
      return false;
    });
  }

  if (direction === 'before') {
    desiredShiftEvent = shiftsForSameDayOnly.reverse().find((shiftEvent) => {
      if (element.dataset.shiftDate && (shiftEvent as HTMLElement).dataset.shiftDate)
        // return (shiftEvent as HTMLElement).dataset.shiftDate?.includes(element.dataset.shiftDate.split('T')[0]);
        return dayjs((shiftEvent as HTMLElement).dataset.shiftDate).diff(dayjs(element.dataset.shiftDate)) < 0;
      return false;
    }) as HTMLElement;
  }
  return desiredShiftEvent;
};

const shiftsTableMoveLeft = (element: HTMLElement) => {
  const left = getAdjacentAddShift(element, 'left');
  if (left) {
    left.focus();
  } else {
    (getEmployeeCellInTheSameRow(element) as HTMLElement).focus();
  }
};

const shiftsTableOverridePressTab = (element: HTMLElement) => {
  const cellBelow = getAdjacentAddShift(element, 'down');

  if (cellBelow) (getEmployeeCellInTheSameRow(cellBelow) as HTMLElement).focus();
};

const employeeCellSpecialShiftTabCallback = (element: HTMLElement): void => {
  const employeeCellAbove = getAdjacentEmployeeCell(element, 'up');
  if (employeeCellAbove) {
    focusLastShiftCellInTheSameRow(employeeCellAbove);
  }
};

const employeeCellKeydownCallbacks = (): KeyDownCallbacks => {
  let callbacks: KeyDownCallbacks = {};

  callbacks = {
    ...callbacks,
    // Not necessary to have separate LeftArrow behaviour - the employee cell is always the first cell in complete FullCalendar component.
    rightArrowCallback: (element) => focusFirstShiftCellInTheSameRow(element),
    upArrowCallback: (element) => {
      const employeeAbove = getAdjacentEmployeeCell(element, 'up');
      if (employeeAbove) employeeAbove.focus();
    },
    downArrowCallback: (element) => {
      const employeeBelow = getAdjacentEmployeeCell(element, 'down');
      if (employeeBelow) employeeBelow.focus();
    },
    // Not necessary to have separate Tab behaviour - the right arrow will always suffice as there will always be shift in front of the employee portion of each row, so we will reuse it.
    specialShiftTabCallback: (element) => employeeCellSpecialShiftTabCallback(element),
  };

  return callbacks;
};

const shiftCellKeydownCallbacks = (callback: KeyDownCallbacks): KeyDownCallbacks => {
  const { enterCallback } = callback;
  let callbacks: KeyDownCallbacks = {
    enterCallback: (element) => {
      if (enterCallback) enterCallback(element);
    },
  };

  callbacks = {
    ...callbacks,
    leftArrowCallback: (element) => shiftsTableMoveLeft(element),
    rightArrowCallback: (element) => {
      const adjacentAddShiftRight = getAdjacentAddShift(element, 'right');
      if (adjacentAddShiftRight) adjacentAddShiftRight.focus();
    },
    upArrowCallback: (element) => {
      const adjacentAddShiftUp = getAdjacentAddShift(element, 'up');
      if (adjacentAddShiftUp) adjacentAddShiftUp.focus();
    },

    downArrowCallback: (element) => {
      const adjacentAddShiftDown = getAdjacentAddShift(element, 'down');
      if (adjacentAddShiftDown) adjacentAddShiftDown.focus();
    },

    specialTabCallback: (element) => shiftsTableOverridePressTab(element),
    // Not necessary to have separate Shift+Tab behaviour - the left arrow callback will always suffice as there will always be employee before the shift portion of each row, so we will reuse it.
  };

  return callbacks;
};

const setEmployeeCellListeners = (element: HTMLElement, resourceId: string): void => {
  if (!resourceId) {
    // No resourceId recieved, not setting any listeners or attributes
    return;
  }

  const callbacksKeyDown: KeyDownCallbacks = employeeCellKeydownCallbacks();

  // eslint-disable-next-line no-case-declarations
  const employeeCell = element;

  employeeCell.dataset.testid = `employee-cell-${resourceId}`;
  employeeCell.classList.add(`calendar-custom-employee-name`);
  employeeCell.dataset.resourceId = resourceId;
  employeeCell.setAttribute('tabindex', '0');
  employeeCell.setAttribute('id', `employee-cell-${resourceId}`);

  employeeCell.addEventListener('keyup', (event) => {
    if (event.key === 'Enter' && callbacksKeyDown.enterCallback) {
      callbacksKeyDown.enterCallback(employeeCell);
    }
  });
  employeeCell.addEventListener('keydown', (event) => {
    if (
      (event.key === 'ArrowRight' || (event.key === 'Tab' && !event.shiftKey)) &&
      callbacksKeyDown.rightArrowCallback
    ) {
      event.preventDefault();
      callbacksKeyDown.rightArrowCallback(employeeCell);
    }

    if (event.key === 'ArrowUp' && callbacksKeyDown.upArrowCallback) {
      callbacksKeyDown.upArrowCallback(employeeCell);
    }

    if (event.key === 'ArrowDown' && callbacksKeyDown.downArrowCallback) {
      callbacksKeyDown.downArrowCallback(employeeCell);
    }

    if (event.key === 'Tab' && event.shiftKey && callbacksKeyDown.specialShiftTabCallback) {
      /**
       * Overriding default Shift+Tab behaviour for tranfesring focus to last cell in row above in
       * Shifts table ONLY if current employeeCell is NOT in the first row
       */
      if (getAdjacentEmployeeCell(employeeCell, 'up')) {
        event.preventDefault();
        callbacksKeyDown.specialShiftTabCallback(employeeCell);
      }
    }
  });
};

const setAddShiftListeners = (
  addShiftIconDiv: HTMLElement,
  index: number,
  rowIndex: number,
  resourceId: string,
  callbackKeyDown: KeyDownCallbacks
): void => {
  if (!resourceId) {
    // No resourceId recieved, not setting any listeners or attributes
    return;
  }

  // Intended use is to modify HTMLElement sent through, so disabling this rule for this function
  /* eslint no-param-reassign: 0 */ // --> OFF
  addShiftIconDiv.classList.add(`calendar-custom-add-shift`);
  addShiftIconDiv.classList.add(`calendar-custom-add-shift-${index}`);
  addShiftIconDiv.setAttribute('tabindex', '0');
  addShiftIconDiv.setAttribute('role', 'button');
  addShiftIconDiv.setAttribute('aria-describedby', `employee-cell-${resourceId}`);
  addShiftIconDiv.dataset.index = index.toString();
  addShiftIconDiv.dataset.rowIndex = rowIndex.toString();
  addShiftIconDiv.dataset.resourceId = resourceId;
  addShiftIconDiv.dataset.testid = `add-shift-cell_row-${rowIndex}_col-${index}_resId-${resourceId}`;

  addShiftIconDiv.addEventListener('keyup', (event) => {
    if (event.key === 'Enter' && callbackKeyDown.enterCallback) {
      callbackKeyDown.enterCallback(addShiftIconDiv);
    }
  });
  addShiftIconDiv.addEventListener('keydown', (event) => {
    if (event.key === 'ArrowLeft' && callbackKeyDown.leftArrowCallback) {
      callbackKeyDown.leftArrowCallback(addShiftIconDiv);
    }
    if (event.key === 'ArrowRight' && callbackKeyDown.rightArrowCallback) {
      callbackKeyDown.rightArrowCallback(addShiftIconDiv);
    }
    if (event.key === 'ArrowUp' && callbackKeyDown.upArrowCallback) {
      callbackKeyDown.upArrowCallback(addShiftIconDiv);
    }
    if (event.key === 'ArrowDown' && callbackKeyDown.downArrowCallback) {
      callbackKeyDown.downArrowCallback(addShiftIconDiv);
    }
    if (event.key === 'Tab' && !event.shiftKey) {
      // Overriding default tab behaviour when not in last column - to simulate moving right (as logic for focusing on shifts is encapsulated in there)
      // and when in last column but not last row - to switch to next row in Employees table (headers on the left).
      // This way, when we get to last column in last row, default tab will occurr and focus will leave table.
      if (getAdjacentAddShift(addShiftIconDiv, 'right') && callbackKeyDown.rightArrowCallback) {
        event.preventDefault();
        callbackKeyDown.rightArrowCallback(addShiftIconDiv);
      } else if (getAdjacentAddShift(addShiftIconDiv, 'down') && callbackKeyDown.specialTabCallback) {
        event.preventDefault();
        callbackKeyDown.specialTabCallback(addShiftIconDiv);
      }
    }
    if (event.key === 'Tab' && event.shiftKey) {
      // Overriding default Shift+Tab behaviour by focusing same-row cell in Employees table (headers on the left) ONLY if current element the in the first column of shifts table
      const lastShiftEvent = getLastShiftEventInSameAddShiftCell(addShiftIconDiv);
      if (lastShiftEvent) {
        if (callbackKeyDown.upArrowCallback) {
          event.preventDefault();
          callbackKeyDown.upArrowCallback(addShiftIconDiv);
        }
      } else if (callbackKeyDown.leftArrowCallback) {
        event.preventDefault();
        callbackKeyDown.leftArrowCallback(addShiftIconDiv);
      }
    }
  });
  /* eslint no-param-reassign: 2 */ // --> ON
};

// TODO: tried getting this argument type right, but without luck
// eslint-disable-next-line
const handleShiftEventKeyDown = (event: any) => {
  // TODO: Discuss why this isn't working
  // Should be working after registering a keyDown event only, but event then - when I come back to it, it still doesn't read it out.
  // const shiftEventDiv = event.target as HTMLElement;
  // shiftEventDiv.setAttribute('aria-describedby', `employee-cell-${shiftEventDiv.dataset.resourceId}`);

  if (event.key === 'ArrowDown') {
    const shiftElementBelow = getAdjacentShift(event.target, 'after');
    if (shiftElementBelow) shiftElementBelow.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
      if (addShiftInSameCell) addShiftInSameCell?.focus();
    }
  }

  if (event.key === 'ArrowUp') {
    const shiftElementAbove = getAdjacentShift(event.target, 'before');
    if (shiftElementAbove) shiftElementAbove.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
      if (addShiftInSameCell) getAdjacentAddShift(addShiftInSameCell, 'up', true)?.focus();
    }
  }

  if (event.key === 'ArrowRight') {
    const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
    if (addShiftInSameCell) getAdjacentAddShift(addShiftInSameCell, 'right')?.focus();
  }

  if (event.key === 'ArrowLeft') {
    const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
    if (addShiftInSameCell) shiftsTableMoveLeft(addShiftInSameCell);
  }
  if (event.key === 'Tab' && !event.shiftKey) {
    event.preventDefault();
    const shiftElementBelow = getAdjacentShift(event.target, 'after');
    if (shiftElementBelow) shiftElementBelow.focus();
    else {
      const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
      if (addShiftInSameCell) addShiftInSameCell?.focus();
    }
  }
  if (event.key === 'Tab' && event.shiftKey) {
    event.preventDefault();
    const addShiftCell = getAddShiftFromShiftEvent(event.target);
    if (addShiftCell) {
      const firstShiftEvent = getFirstShiftEventInSameAddShiftCell(addShiftCell);
      if (event.target.dataset.testid === firstShiftEvent?.dataset.testid) {
        const addShiftInSameCell = getAddShiftFromShiftEvent(event.target);
        if (addShiftInSameCell) shiftsTableMoveLeft(addShiftInSameCell);
      } else {
        const shiftEventAbove = getAdjacentShift(event.target, 'before');
        if (shiftEventAbove) shiftEventAbove.focus();
      }
    }
  }
};

const getShiftInfoFromEvent = (
  title: string,
  shiftDateTime: string
): { startTime: string; endTime: string; jobCodeName: string; shiftDate: string } => {
  const startTime = getReadableTimeFormatFromShiftEventLabel(title.split('-')[0]);
  const endTimeAndJobcode = title.split('-')[1].trim();
  const endTime = getReadableTimeFormatFromShiftEventLabel(
    endTimeAndJobcode.substring(0, endTimeAndJobcode.indexOf(' '))
  );
  const jobCodeName = endTimeAndJobcode.substring(endTimeAndJobcode.indexOf(' ') + 1);
  const shiftDate = dayjs(shiftDateTime.split('T')[0]).format('LL');

  return { startTime, endTime, jobCodeName, shiftDate };
};

const SchedulingA11yUtilities = {
  setEmployeeCellListeners,
  setAddShiftListeners,
  getAdjacentEmployeeCell,
  getAdjacentAddShift,
  employeeCellKeydownCallbacks,
  shiftCellKeydownCallbacks,
  handleShiftEventKeyDown,
  getShiftInfoFromEvent,
};

export default SchedulingA11yUtilities;
