import { Res } from '@cbo/shared-library';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { GridProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { getCurrencyFormat } from '../../utils';

export type TCurrencyProps = {
  formContext: UseFormReturn<FieldValues>;
  gridProps: GridProps;
  label: string;
  name: string;
  dataTestId: string;
  preferences: Res.Admin.UserPreferences | null;
  required?: boolean;
  allowNegative?: boolean;
  onValueChange?: (newValue: NumberFormatValues) => void;
  inputValue?: number;
  helperText?: string;
  readOnly?: boolean;
  defaultValue?: number;
};

// in case we are dealing with objects
// e.g name = rules.0.rule.amount
function hasError(errorObject: FieldErrors<FieldValues>, dotNotationPath: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let returnValue: any = errorObject;

  const tokens = dotNotationPath.split('.');

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const index in tokens) {
    returnValue = returnValue[tokens[index]];
    if (!returnValue) break;
  }

  return !!returnValue;
}

function CurrencyTextField(props: TCurrencyProps) {
  const {
    formContext,
    gridProps,
    label,
    required,
    allowNegative,
    name,
    dataTestId,
    preferences,
    onValueChange,
    inputValue,
    helperText,
    readOnly,
    defaultValue,
  } = props;

  const {
    control,
    register,
    formState: { errors },
  } = formContext;

  register(name, { required });

  const { t } = useTranslation();
  const { options, locale } = getCurrencyFormat(preferences);
  const opts: Intl.NumberFormatOptions = { ...(options as Intl.NumberFormatOptions), style: 'currency' };

  // Using user's preferences determine currency format
  const parts = Intl.NumberFormat(locale, opts).formatToParts(123456.78);
  let currencySymbol: string | undefined;
  let decimalSymbol: string | undefined;
  let thousandSeparator: string | undefined;
  parts.forEach((v) => {
    if (v.type === 'currency') {
      currencySymbol = v.value;
    } else if (v.type === 'decimal') {
      decimalSymbol = v.value;
    } else if (v.type === 'group') {
      thousandSeparator = v.value;
    }
  });

  const [prefix, setPrefix] = useState<string>();
  const [suffix, setSuffix] = useState<string>();

  let helperTextValue = '';
  if (hasError(errors, name)) {
    helperTextValue = t('formMessage.required');
  } else if (helperText) {
    helperTextValue = helperText;
  }

  useEffect(() => {
    if (preferences !== null && preferences.showCurrencySymbol) {
      if (parts[0].type === 'currency') {
        setPrefix(`${currencySymbol} `);
      } else {
        setSuffix(` ${currencySymbol}`);
      }
    }
  }, [preferences, parts, currencySymbol, setPrefix, setSuffix]);

  return (
    <Controller
      control={control}
      name={name}
      key={name}
      render={({ field: { ref, onChange, value, ...field } }) => (
        <Grid item {...gridProps}>
          <NumericFormat
            {...field}
            inputRef={ref}
            value={inputValue ?? value}
            onValueChange={(v) => {
              onChange(v.floatValue ?? '');
              if (onValueChange) onValueChange(v);
            }}
            customInput={TextField}
            prefix={prefix}
            suffix={suffix}
            fixedDecimalScale
            thousandSeparator={thousandSeparator}
            decimalScale={2}
            decimalSeparator={decimalSymbol}
            variant='standard'
            label={t(label)}
            fullWidth
            required={required}
            allowNegative={allowNegative}
            error={hasError(errors, name)}
            data-testid={dataTestId}
            defaultValue={defaultValue}
            helperText={helperTextValue}
            inputProps={{ 'data-testid': `${dataTestId}-field`, readOnly }}
          />
        </Grid>
      )}
    />
  );
}

CurrencyTextField.defaultProps = {
  required: false,
  allowNegative: false,
  onValueChange: undefined,
  inputValue: undefined,
  helperText: undefined,
  readOnly: false,
  defaultValue: undefined,
};

export default CurrencyTextField;
