import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  createContext as createLDContext,
  FeatureFlag,
  Package,
  PackageToFeatureFlagMapping,
} from '@cbo/shared-library';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import ContextProviderProps from '../models/ContextProviderProps';
import { useUsers } from './userContext';
import { useOrgContext } from '../org/CommonComponentWrapper';

export interface PackageSelectionContext {
  [Package.STARTER]: boolean;
  [Package.ADVANCED_ANALYTICS]: boolean;
  [Package.LABOR_PAYROLL]: boolean;
  [Package.INVENTORY]: boolean;
  [Package.SCHEDULING]: boolean;
  packagesSet: boolean;
}

const defaultPackages: Record<Package, boolean> = {
  [Package.STARTER]: true,
  [Package.ADVANCED_ANALYTICS]: false,
  [Package.LABOR_PAYROLL]: false,
  [Package.INVENTORY]: false,
  [Package.SCHEDULING]: false,
};

const packageSelectionOff: Record<Package, boolean> = {
  [Package.STARTER]: true,
  [Package.ADVANCED_ANALYTICS]: true,
  [Package.LABOR_PAYROLL]: true,
  [Package.INVENTORY]: true,
  [Package.SCHEDULING]: true,
};

export const PackageContext = createContext<PackageSelectionContext>({
  ...defaultPackages,
  packagesSet: false,
});

export const usePackages = () => useContext(PackageContext);

export const usePackage = (type: Package): boolean => {
  const context = useContext(PackageContext);
  return context[type] ?? false;
};

export function PackageContextProvider({ children }: ContextProviderProps) {
  const userContext = useUsers();
  const { organization } = useOrgContext();
  const client = useLDClient();
  const flags = useFlags();
  const [packages, setPackages] = useState<Record<Package, boolean>>(defaultPackages);
  const [packagesSet, setPackagesSet] = useState(false);

  const providerValue = useMemo(
    () => ({
      [Package.STARTER]: true, // Due to changing requirements, we are not deleting the starter package. We will just default true always
      [Package.ADVANCED_ANALYTICS]: packages.coreAdvancedAnalytics,
      [Package.INVENTORY]: packages.coreInventory,
      [Package.LABOR_PAYROLL]: packages.coreLaborPayroll,
      [Package.SCHEDULING]: packages.coreScheduling,
      packagesSet,
    }),
    [packages, packagesSet]
  );

  useEffect(() => {
    const currentContext = client?.getContext() as LDSingleKindContext | undefined;
    if (userContext.isOrgSwitching) {
      setPackages(defaultPackages);
      setPackagesSet(false);
    } else if (organization) {
      const context = createLDContext(process.env.REACT_APP_RCC_CONFIG_ENV ?? 'dev', organization.organizationName);
      client?.identify(context);

      if (currentContext?.kind === 'asm-org') {
        // TODO: Once migration for package selection is complete, remove the check for the feature flag
        if (!flags[FeatureFlag.usePackageSelection]) {
          setPackages(packageSelectionOff);
        } else {
          const updatedPackages = { ...defaultPackages };
          Object.values(Package).forEach((packageType) => {
            updatedPackages[packageType] = flags[PackageToFeatureFlagMapping[packageType]];
          });
          // until future default/starter discussion is finalized, we are defaulting starter to true as new default for 1.13
          updatedPackages[Package.STARTER] = true;
          setPackages(updatedPackages);
        }
        setPackagesSet(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags, userContext.isOrgSwitching, organization]);

  return <PackageContext.Provider value={providerValue}>{children}</PackageContext.Provider>;
}

export default PackageContextProvider;
