import i18next from 'i18next';
import { CboRole, FeatureFlag, PersonaRole } from '@cbo/shared-library';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';
import { loggedInAsPersona } from '../../../lib/persona';
import { useFeatureFlag } from '../../../utils/hooks/useFeatureFlag';

export default function useAccountRoutes(): NavItem[] {
  const user = useUsers();
  const cboLaborRearchitecture = useFeatureFlag(FeatureFlag.laborRearchitecture);

  const accountRoutes: NavItem[] = [];

  accountRoutes.push({
    title: i18next.t('navItems.accountPreference'),
    href: routes.ACCOUNT_PREFERENCES,
    testid: 'account-preference-menu-item',
  });

  if (isPermitted(user, [CboRole.NOTIFICATION_GROUP_EDIT]) && cboLaborRearchitecture) {
    accountRoutes.push({
      title: i18next.t('navItems.notificationSettings'),
      href: routes.NOTIFICATION_SETTINGS,
      testid: 'notification-settings-menu-item',
    });
  }

  if (
    isPermitted(user, [CboRole.EMPLOYEE_PERSONAL_EDIT]) &&
    loggedInAsPersona(user, [PersonaRole.EMPLOYEE, PersonaRole.MANAGER, PersonaRole.ASSISTANT_MANAGER]) &&
    cboLaborRearchitecture
  ) {
    accountRoutes.push({
      title: i18next.t('navItems.myInformation'),
      href: routes.EMPLOYEE_REGISTRATION_HOME,
      testid: 'employee-registration-module',
    });
  }

  return accountRoutes;
}
