import { Req } from '@cbo/shared-library';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback } from 'react';
import { useFirebaseApi } from '../../contexts/firebaseApiContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasSiteIds(payload: any): payload is { siteIds: string[] } {
  return payload && typeof payload === 'object' && 'siteIds' in payload && Array.isArray(payload.siteIds);
}

export default () => {
  const { oktaAuth } = useOktaAuth();
  const { bslProxy } = useFirebaseApi();
  return useCallback(
    async <T>(request: Req.Firebase.BslProxyRequest): Promise<T> => {
      const { accessToken } = await oktaAuth.tokenManager.getTokens();
      if (hasSiteIds(request.payload)) {
        request.validateSiteIds = true;
      }
      if (!bslProxy) {
        throw new Error('bslProxy firebase function is not assigned');
      }
      if (!accessToken) {
        throw new Error('No okta token found');
      }
      const requestNcrid: Req.Firebase.BslProxyRequestNcrId = { ...request, oktaJwt: accessToken.accessToken };
      const result = await bslProxy(requestNcrid);
      return result.data as T;
    },
    [bslProxy, oktaAuth.tokenManager]
  );
};
