import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { removeLocalStorage } from 'src/utils/local-storage.utils';

export const RccCacheOperator = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const { cache } = useSWRConfig();

    useEffect(
        function clearUnauthenticatedCache() {
            if (isAuthenticated === false) {
                removeLocalStorage();

                for (let key of cache.keys()) {
                    cache.delete(key);
                }
            }
        },
        [isAuthenticated],
    );

    return null;
};
