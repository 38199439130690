import type { ReactElement, ReactNode } from 'react';
import React, { Children, useEffect, useMemo, useRef } from 'react';
import { getLevel, matchPath } from './navigationUtils';
import NavigationView, { NavigationViewProps } from './NavigationView';
import { NavigationProps, NavigationProvider, useNavigation } from './NavigationContext';

// eslint-disable-next-line react/require-default-props
function NavigationSwitch(props: { children?: ReactNode } & Partial<NavigationProps>) {
  const navigation = useNavigation();
  const { children, selection = navigation.selection, view = navigation.view } = props;

  const childViews = useMemo(
    () =>
      Children.toArray(children)?.filter((child) => {
        if (!React.isValidElement(child)) {
          return false;
        }
        return child.type === NavigationView;
      }) as ReactElement<NavigationViewProps>[],
    [children]
  );

  // find matching route
  const mountedComponent = useMemo(
    () =>
      childViews
        .sort((a, b) => (getLevel(a?.props) <= getLevel(b?.props) ? 1 : -1))
        .find((x) => matchPath(view, x.props.path)),
    [childViews, view]
  );

  // detect first render for disabling animation
  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <NavigationProvider selection={selection} view={view}>
      {mountedComponent || null}
    </NavigationProvider>
  );
}

export default NavigationSwitch;
