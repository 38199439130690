import { EffectiveOrgExcemtpionList } from '../constants/exempt-list';
import { Organization } from '../types';

export const getOrgShortName = (orgName: string) => {
    const splitName = orgName.split('/');
    return splitName.length == 1 ? orgName : splitName.slice(-2)[0];
};

export const formatOrganizations = (orgs: Organization[]): Organization[] => {
    const filtered = orgs.filter((o: Organization) => {
        const orgHierarchy: string[] = o.organizationName.split('/').filter((s) => !!s);
        return !(orgHierarchy.length == 1 && EffectiveOrgExcemtpionList.includes(orgHierarchy[0]));
    });

    const childrenIds: Set<string> = new Set();

    for (let org of filtered) {
        const grantedChildren = filtered.filter((o: Organization) => {
            return (
                o.organizationName.startsWith(org.organizationName) &&
                o.organizationName.length > org.organizationName.length
            );
        });

        const children = [];

        for (let o of grantedChildren) {
            children.push(getOrgShortName(o.organizationName));
            childrenIds.add(o.id);
        }

        org.children = children;
    }

    const filteredAndGrouped = filtered.filter((o: Organization) => !childrenIds.has(o.id));

    filteredAndGrouped.forEach((o: Organization) => {
        o.organizationName = getOrgShortName(o.organizationName);
        o.children ??= [];
    });

    return filteredAndGrouped.sort((a, b) => a.organizationName.localeCompare(b.organizationName));
};
