import { SWRResponse } from "swr";

const appCacheKey = 'rcc-app-cache';

export function localStorageProvider() {
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map<string, SWRResponse<any>>(JSON.parse(localStorage.getItem(appCacheKey) || '[]'));

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
        const appCache = Array.from(map.entries());
        const cleanCache = [];

        //Only include data that didn't error
        appCache.forEach((entry) => {
            if (entry.length && entry.length > 1 && !!entry[1].data) {
                cleanCache.push(entry);
            }
        })

        localStorage.setItem(appCacheKey, JSON.stringify(cleanCache));
    });

    // We still use the map for write & read for performance.
    return map;
}

export function removeLocalStorage() {
    localStorage.removeItem(appCacheKey);
}
