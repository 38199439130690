import * as yup from 'yup';
import { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { isEqual, isNil } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { Theme, useMediaQuery } from '@mui/material';
import createFormTextField from '../../components/FormTextFields/FormTextFields';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import { useGlAccountsQuery, useGetGlAccountFilters } from '../requests/queries';
import { GlAccount } from '../requests/requests';
import Multiselect, { IOption } from '../../components/Multiselect/Multiselect';
import { GlAccountsSidebarState } from './GeneralLedgerAccountsSidebar';
import { useUsers } from '../../contexts/userContext';
import SalesUtilities from '../utilities';
import GeneralLedgerAccountsDataGrid from './GeneralLedgerAccountsDataGrid';

export enum GlAccountsFilterIsActive {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface GlAccountsFilters {
  isActive: string;
  glCategory: string[];
  glType: string[];
  glDetailType: string[];
}

interface GlAccountRow {
  [key: string]: unknown;
  hierarchy: string[];
  id: string;
}

export type GlAccountRows = GlAccount & GlAccountRow;

export type GeneralLedgerAccountsManageReportProps = {
  isGlAccountRowOpen: boolean;
  setIsGlAccountRowOpen: Dispatch<SetStateAction<boolean>>;
  sidebarState: GlAccountsSidebarState;
  setSidebarState: Dispatch<SetStateAction<GlAccountsSidebarState>>;
};

function GeneralLedgerAccountsManageReport(props: GeneralLedgerAccountsManageReportProps) {
  const { isGlAccountRowOpen, setIsGlAccountRowOpen, sidebarState, setSidebarState } = props;
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] = useState<(GlAccountsFilters | undefined)[]>();
  const isMobile = useMediaQuery((themes: Theme) => themes.breakpoints.down('sm'));
  const validationSchema = useMemo(() => yup.object({}), []);
  const resolver = useYupValidationResolver(validationSchema);
  const [glCategories, setGlCategories] = useState<string[]>([]);
  const [glCategoryNameToId, setGlCategoryNameToId] = useState<{ [key: string]: number }>({});
  const [glType, setGlType] = useState<string[]>([]);
  const [glDetailType, setGlDetailType] = useState<string[]>([]);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true); // Used to load the initial filter options
  const user = useUsers();

  const formContext = useForm({
    mode: 'onChange',
    resolver,
    defaultValues: {
      isActive: [],
      glCategory: [],
      glType: [],
      glDetailType: [],
    },
  });

  const { register, watch } = formContext;

  const glAccountsFilterConfig: FieldValues[] = useMemo(
    () => [
      {
        name: 'isActive',
        isDashboardFilter: true,
        required: false,
        showCheckbox: true,
        multiple: true,
        dataTestId: 'isActive',
        variant: 'autocomplete',
        gridProps: { width: 275, maxWidth: 275, paddingTop: 2, flexGrow: 1 },
        options: [GlAccountsFilterIsActive.ACTIVE, GlAccountsFilterIsActive.INACTIVE],
      },
      {
        name: 'glCategory',
        isDashboardFilter: true,
        required: false,
        showCheckbox: true,
        multiple: true,
        dataTestId: 'glCategory',
        variant: 'autocomplete',
        gridProps: { width: 275, maxWidth: 275, paddingTop: 2, flexGrow: 1 },
        options: glCategories,
      },
    ],
    [glCategories]
  );

  const glTypeFilter = useMemo(() => {
    const filter = (
      <Multiselect
        name='glType'
        control={formContext.control}
        plural={t('admin.glAccountConfiguration.generalLedgerAccounts.glTypes')}
        singular={t('admin.glAccountConfiguration.generalLedgerAccounts.glType')}
        options={glType.map((e) => ({
          group: t('admin.glAccountConfiguration.generalLedgerAccounts.selectAllGlTypes'),
          title: e,
        }))}
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glType')}
        resetField={formContext.resetField}
        collapsibleGroups={false}
      />
    );
    return filter;
  }, [formContext, t, glType]);

  const glDetailTypeFilter = useMemo(() => {
    const filter = (
      <Multiselect
        name='glDetailType'
        control={formContext.control}
        plural={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailTypes')}
        singular={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType')}
        options={glDetailType.map((e) => ({
          group: t('admin.glAccountConfiguration.generalLedgerAccounts.selectAllGlDetailTypes'),
          title: e,
        }))}
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType')}
        resetField={formContext.resetField}
        collapsibleGroups={false}
      />
    );
    return filter;
  }, [formContext, t, glDetailType]);

  const { glAccounts, setGlAccountsContext, isError, isLoading, refetchGlAccounts } = useGlAccountsQuery(
    user.org?.bslId
  );

  const { glAccountFilters, isGetGlAccountFiltersDataLoading, isGetGlAccountFiltersError } = useGetGlAccountFilters();

  const handleFilterChange = async (params: {
    isActive: string;
    glCategory: string[];
    glType: IOption[];
    glDetailType: IOption[];
  }) => {
    setGlAccountsContext({
      isActive: params.isActive,
      categoryIds: params.glCategory.map((e) => glCategoryNameToId[e]),
      accountTypes: params.glType.map((type) => type.title),
      accountDetailTypes: params.glDetailType.map((detailType) => detailType.title),
    });
  };

  useEffect(() => {
    // Get all available filters
    if (
      !isNil(glAccountFilters) &&
      !isGetGlAccountFiltersError &&
      !isGetGlAccountFiltersDataLoading &&
      filtersLoading
    ) {
      setGlType(glAccountFilters.accountTypes);
      setGlDetailType(glAccountFilters.accountDetailTypes);
      const categoryNamesToIdsMap = glAccountFilters.categoryInfo
        .map((category) => ({
          [`${category.categoryName}`]: category.categoryId,
        }))
        .reduce((a, b) => ({ ...a, ...b }), {});
      setGlCategories(Object.keys(categoryNamesToIdsMap));
      setGlCategoryNameToId(categoryNamesToIdsMap);
      setFiltersLoading(false);
    }
  }, [filtersLoading, glAccountFilters, isGetGlAccountFiltersDataLoading, isGetGlAccountFiltersError]);

  // Watch for changes to update filters...
  const data = watch();

  useEffect(() => {
    if (isEqual(selectedFilters, data)) {
      return;
    }

    handleFilterChange({
      isActive: SalesUtilities.updateGlAccountsIsActiveFilter(data.isActive as string[]),
      glCategory: data.glCategory,
      glType: data.glType,
      glDetailType: data.glDetailType,
    });
    setSelectedFilters(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box marginRight={isGlAccountRowOpen && !isMobile ? '350px' : '0'}>
      <Grid item xs={6}>
        {t('admin.glAccountConfiguration.generalLedgerAccounts.manageIntro')}
      </Grid>
      <FormContainer formContext={formContext}>
        <Grid container spacing={1.5} pt={2} mb={2}>
          {!filtersLoading
            ? [
                ...glAccountsFilterConfig.map((field: FieldValues) =>
                  createFormTextField(
                    field,
                    { t, tKey: 'admin.glAccountConfiguration.generalLedgerAccounts' },
                    register
                  )
                ),
                <Grid item key='gl-type-multiselect' sx={{ width: 214, maxWidth: 275, paddingTop: 2, flexGrow: 1 }}>
                  {glTypeFilter}
                </Grid>,
                <Grid
                  item
                  key='gl-detail-type-multiselect'
                  sx={{ width: 214, maxWidth: 275, paddingTop: 2, flexGrow: 1 }}
                >
                  {glDetailTypeFilter}
                </Grid>,
              ]
            : glAccountsFilterConfig.map((field: FieldValues) => (
                <Grid item key={field.name}>
                  <Skeleton variant='rectangular' width={214} height={48} />
                </Grid>
              ))}
        </Grid>
      </FormContainer>
      <GeneralLedgerAccountsDataGrid
        isGlAccountRowOpen={isGlAccountRowOpen}
        setIsGlAccountRowOpen={setIsGlAccountRowOpen}
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
        glAccounts={glAccounts}
        isError={isError}
        isLoading={isLoading}
        refetchGlAccounts={refetchGlAccounts}
      />
    </Box>
  );
}

export default GeneralLedgerAccountsManageReport;
