import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import PointOfSaleWithDollarSignOutline from '../../../customIcons/PointOfSaleWithDollarSignOutline';

export enum DataGridActionsMenuItems {
  Deactivate = 'deactivate',
  Activate = 'activate',
  Delete = 'delete',
  Edit = 'edit',
  Approve = 'approve',
  Declare = 'declare',
  Remove = 'remove',
}

export type CustomActionsMenuCellProps = {
  id?: string;
  actions: DataGridActionsMenuItems[];
  onClickFunctions?: Partial<Record<DataGridActionsMenuItems, () => void>>;
  buttonTitles?: Partial<Record<DataGridActionsMenuItems, string>>;
  tooltipTitles?: Partial<Record<DataGridActionsMenuItems, string>>;
  extraPermissions?: Partial<Record<DataGridActionsMenuItems, boolean>>;
  disabled?: Partial<Record<DataGridActionsMenuItems, boolean>>;
  isActive?: boolean;
};

function CustomActionsMenuCell(props: CustomActionsMenuCellProps) {
  const { id, isActive, actions, onClickFunctions, buttonTitles, tooltipTitles, extraPermissions, disabled } = props;
  const { t } = useTranslation();

  // menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // custom actions
  const tooltipProps: Partial<TooltipProps> = {
    placement: 'left',
    arrow: true,
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    },
  };

  const getPermissionValue = (key: DataGridActionsMenuItems) => {
    if (extraPermissions) {
      if (Object.keys(extraPermissions).includes(key)) {
        return extraPermissions[key];
      }
      return true;
    }
    return true;
  };

  const isDisabled = (key: DataGridActionsMenuItems) => {
    if (disabled) {
      if (Object.keys(disabled).includes(key)) {
        return disabled[key];
      }
    }
    return false;
  };

  const onClick = (key: DataGridActionsMenuItems) => {
    if (onClickFunctions) {
      onClickFunctions[key]?.();
      handleClose();
    }
  };

  const actionOptions = actions
    .filter((action) => getPermissionValue(action))
    .map((action) => {
      if (action === DataGridActionsMenuItems.Edit) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Edit] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-edit-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Edit)}
              disabled={isDisabled(DataGridActionsMenuItems.Edit)}
            >
              <ListItemIcon>
                <EditIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Edit]
                  ? buttonTitles[DataGridActionsMenuItems.Edit]
                  : t('buttonText.edit')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Activate && !isActive) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Activate] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-activate-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Activate)}
              disabled={isDisabled(DataGridActionsMenuItems.Activate)}
            >
              <ListItemIcon>
                <CheckCircleIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Activate]
                  ? buttonTitles[DataGridActionsMenuItems.Activate]
                  : t('buttonText.activate')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Deactivate && isActive) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Deactivate] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-deactivate-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Deactivate)}
              disabled={isDisabled(DataGridActionsMenuItems.Deactivate)}
            >
              <ListItemIcon>
                <HighlightOff fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Deactivate]
                  ? buttonTitles[DataGridActionsMenuItems.Deactivate]
                  : t('buttonText.deactivate')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Delete) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Delete] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-delete-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Delete)}
              disabled={isDisabled(DataGridActionsMenuItems.Delete)}
            >
              <ListItemIcon>
                <DeleteIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Delete]
                  ? buttonTitles[DataGridActionsMenuItems.Delete]
                  : t('buttonText.delete')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Approve) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Approve] : undefined}
            key={uuidv4()}
          >
            {/* Surrounding with a span because https://mui.com/material-ui/react-tooltip/#disabled-elements */}
            <span>
              <MenuItem
                data-testid={`${id}-approve-btn`}
                onClick={() => onClick(DataGridActionsMenuItems.Approve)}
                disabled={isDisabled(DataGridActionsMenuItems.Approve)}
              >
                <ListItemIcon>
                  <CheckCircleOutline fontSize='small' />
                </ListItemIcon>
                <ListItemText>
                  {buttonTitles && buttonTitles[DataGridActionsMenuItems.Approve]
                    ? buttonTitles[DataGridActionsMenuItems.Approve]
                    : t('buttonText.approve')}
                </ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Declare) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Declare] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-declare-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Declare)}
              disabled={isDisabled(DataGridActionsMenuItems.Declare)}
            >
              <ListItemIcon>
                <PointOfSaleWithDollarSignOutline />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Declare]
                  ? buttonTitles[DataGridActionsMenuItems.Declare]
                  : t('buttonText.declare')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      if (action === DataGridActionsMenuItems.Remove) {
        return (
          <Tooltip
            {...tooltipProps}
            title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Remove] : undefined}
            key={uuidv4()}
          >
            <MenuItem
              data-testid={`${id}-remove-btn`}
              onClick={() => onClick(DataGridActionsMenuItems.Remove)}
              disabled={isDisabled(DataGridActionsMenuItems.Remove)}
            >
              <ListItemIcon>
                <RemoveCircleOutline fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                {buttonTitles && buttonTitles[DataGridActionsMenuItems.Remove]
                  ? buttonTitles[DataGridActionsMenuItems.Remove]
                  : t('buttonText.remove')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        );
      }

      return null;
    });

  return (
    <>
      <IconButton
        id='actions'
        aria-label='actions'
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        data-testid='actions-menu-button'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'actions',
        }}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actionOptions}
      </Menu>
    </>
  );
}

CustomActionsMenuCell.defaultProps = {
  onClickFunctions: undefined,
  buttonTitles: undefined,
  tooltipTitles: undefined,
  isActive: false,
  extraPermissions: undefined,
  disabled: false,
  id: '',
};

export default CustomActionsMenuCell;
