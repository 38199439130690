import { RccEventKeys } from '..';

type EEListener = {
    fn: VoidFunction;
    fnOnce?: VoidFunction;
};
//Based off of https://github.com/scottcorgan/tiny-emitter/blob/master/index.js
export class RccEventEmitter {
    private events: Record<string, EEListener[]> = {};

    public on(name: string, callback: VoidFunction) {
        const e = this.events;
        (e[name] || (e[name] = [])).push({
            fn: callback,
        });

        return this;
    }

    public once(name: string, callback: VoidFunction) {
        const listener = () => {
            this.off(name, listener);
            callback();
        };

        const e = this.events;
        (e[name] || (e[name] = [])).push({
            fn: listener,
            fnOnce: callback,
        });

        return this;
    }

    public emit(name: string) {
        var evtArr = (this.events[name] || []).slice();
        var i = 0;
        var len = evtArr.length;

        for (i; i < len; i++) {
            evtArr[i].fn();
        }

        return this;
    }

    public off(name: string, callback: VoidFunction) {
        const e = this.events;
        var evts = e[name];
        var liveEvents = [];

        if (evts && callback) {
            for (var i = 0, len = evts.length; i < len; i++) {
                if (evts[i].fn !== callback && evts[i]?.fnOnce !== callback)
                    liveEvents.push(evts[i]);
            }
        }

        liveEvents.length ? (e[name] = liveEvents) : delete e[name];

        return this;
    }
}

export class RccEvents {
    private emitter: RccEventEmitter;

    constructor(emitter: RccEventEmitter) {
        this.emitter = emitter;
    }

    public on(name: RccEventKeys, callback: VoidFunction) {
        this.emitter.on(name, callback);
    }

    public off(name: RccEventKeys, callback: VoidFunction) {
        this.emitter.off(name, callback);
    }

    public once(name: RccEventKeys, callback: VoidFunction) {
        this.emitter.once(name, callback);
    }
}
