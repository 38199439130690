const isObject = (object) => {
    return object != null && typeof object === 'object';
};

export const isDeepEqual = (object1, object2): boolean => {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (var key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);
        const isObjectsAndNotEqualCheck = isObjects && !isDeepEqual(value1, value2);
        const isNotObjectsAndNotEqualCheck = !isObjects && value1 !== value2;

        if (isObjectsAndNotEqualCheck || isNotObjectsAndNotEqualCheck) {
            return false;
        }
    }
    return true;
};
